export enum SignalQuality {
    DATA_RATE_0 = 0,
    DATA_RATE_1 = 1,
    DATA_RATE_2 = 2,
    DATA_RATE_3 = 3,
    DATA_RATE_4 = 4,
    DATA_RATE_5 = 5
}

export interface IModemDTO {
    deviceEui: string;
    firmwareVersion: string;
    serial: number;
    updatedAt: Date;
    modemSignalQuality: SignalQuality;
    rssi: number;
    snr: number;
    imei: string;
    iccid: string;
    imsi: string;
    plmn: string;
    bootCounter: number;
}

export class ModemDTO implements IModemDTO {
    public deviceEui: string;
    public firmwareVersion: string;
    public firmware: string;
    public serial: number;
    public updatedAt: Date;
    public modemSignalQuality: SignalQuality;
    public rssi: number;
    public snr: number;
    public imei: string;
    public iccid: string;
    public imsi: string;
    public plmn: string;
    public bootCounter: number;

    static getModemDevEUI(dev): string {
        if (dev.modemDTO === null) {
            return 'Sem Modem';
        } else {
            return dev.modemDTO.eui;
        }
    }

    public static getSinalColor(signalQuality: SignalQuality): string {
        switch (signalQuality) {
            case SignalQuality.DATA_RATE_0:
                return "#000";
            case SignalQuality.DATA_RATE_1:
                return "#FF0000";
            case SignalQuality.DATA_RATE_2:
                return "#EF8181";
            case SignalQuality.DATA_RATE_3:
                return "#EFBD72";
            case SignalQuality.DATA_RATE_4:
                return "#7CFC00";
            case SignalQuality.DATA_RATE_5:
                return "#32CD32";
        }
    }

    static getTelOperator(plmn: string) {
        switch (plmn) {
            case '72402':
            case '72403':
            case '72404':
                return 'TIM';
            case '72411':
            case '72406':
            case '72410':
            case '72423':
                return 'VIVO';
            case '72405':
            case '72438':
                return 'CLARO';
            case '99940':
                return 'NOKIA';
        }
    }

    static getSignalName(signalQuality: SignalQuality) {
        switch (signalQuality) {
            case SignalQuality.DATA_RATE_1:
                return "1 Barra";
            case SignalQuality.DATA_RATE_2:
                return "2 Barras";
            case SignalQuality.DATA_RATE_3:
                return "3 Barras";
            case SignalQuality.DATA_RATE_4:
                return "4 Barras";
            case SignalQuality.DATA_RATE_5:
                return "5 Barras";
            default:
                return 'Nunca Visto';
        }
    }
}
