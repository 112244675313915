import { Observable } from 'rxjs';
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { IClusterBilling, IClusterBillingCheck } from './../../core/models/ClusterBillingDTO';
import { IogHttpService } from 'src/app/security/iog-http.service';
import { environment } from "src/environments/environment";
import { IAuditoriaFilters } from 'src/app/core/models/IAuditoriaDTO';
import { _buildComandTypeQueryList, _buildStatusQueryList } from './tickets.service';

@Injectable({ providedIn: 'root' })

export class ExportsDataServices {
    static url: string = environment.apiUrlBack + '/export/';

    constructor(private ioghttpService: IogHttpService) { }


    /**
     * Riquisita um arquico cvs com todas as leituras do medidor
     * @param {string} meterSerial serial do medidor que vai requisitar as energias
     * @returns arquivo com as leituras
     */
    exportMeterMassMemory(meterSerial: string): any {
        return this.ioghttpService.get(ExportsDataServices.url + `export-readings?serial=${meterSerial}`, { headers: this._getHeaders(), responseType: 'blob' as 'json' });
    }



    /**
     * Riquisita um arquico cvs com todas frozen data
     * @param {string} meterSerial serial do medidor que vai requisitar o frozen data
     * @returns {csv}arquivo com frozen data
     */
    exportMeterFrozenData(meterSerial: string): any {
        return this.ioghttpService.get(ExportsDataServices.url + `export-frozen-data?serial=${meterSerial}`, { headers: this._getHeaders(), responseType: 'blob' as 'json' });
    }


    /**
    * Riquisita um arquico cvs com todas frozen data
    * @param {string} meterSerial serial do medidor que vai requisitar o frozen data
    * @returns arquivo com frozen data
    */
    exportMeterQee(meterSerial: string): any {
        return this.ioghttpService.get(ExportsDataServices.url + `export-qee?serial=${meterSerial}`, { headers: this._getHeaders(), responseType: 'blob' as 'json' });
    }


    /**
   * Envia a lista de medidores requisitando o faturamento
   * @param {IClusterBilling[]} file arquivo contento o serial e as datas para o faturamneto
   * @returns arquivo com o faturamnento
   */
    exportMetersBilling(file: IClusterBilling[], dateTime: Date) {
        return this.ioghttpService.post(ExportsDataServices.url + `export-billings?dateTime=${dateTime}`, file, { headers: this._getHeaders(), responseType: 'blob' as 'json' });
    }

    /**
     * Faz o pré-processamneto do faturamento para saber se existe ou não uma leitura do medidor com a data informada 
     * @param {IClusterBilling[]} billings lista de leituras que vai ser pré-processada
     * @returns {IClusterBillingCheck[]} respostas do pré-procesamento indicando se existe a leitura ou o motivo de não existir 
     */
    preProcessBilings(billings: IClusterBilling[], dateTime: Date): Observable<IClusterBillingCheck[]> {
        return this.ioghttpService.post<IClusterBillingCheck[]>(ExportsDataServices.url + 'check-billings?dateTime=' + dateTime, billings);
    }

    exportUserTableTickets(filters: IAuditoriaFilters): any {
        return this.ioghttpService.get(ExportsDataServices.url + `export-tickets?sortyBy=${filters.sortBy}&sortOrder=${filters.sortOrder}&endDate=${filters.endDate}&startDate=${filters.startDate}&email=${filters.userEmail || ''}&showSystem=${filters.showSystemTickets}&serialMeter=${filters.meterSerial}${_buildComandTypeQueryList(filters)}${_buildStatusQueryList(filters)}`, { headers: this._getHeaders(), responseType: 'blob' as 'json' });
    }


    private _getHeaders(): HttpHeaders {
        return new HttpHeaders({
            Authorization: `Bearer ${localStorage.getItem(environment.token)}`,
        });
    }

}