import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";

import { IogHttpService } from "../../security/iog-http.service";
import { MeterDTO } from "../../core/models/MeterDTO";
import { environment } from "../../../environments/environment";
import { MeterModelDTO } from "src/app/core/models/MeterModelDTO";
import {
  IMetersComissionedStatisticDTO,
  IOnlineEstatisticDTO,
} from "src/app/core/models/OnlineStatisticDTO";
import { Observable } from "rxjs";
import {
  IPaginationResponseDTO,
  PaginationResponseDTO,
} from "src/app/core/models/PaginationResponseDTO";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class MetersService {
  static url = environment.apiUrlBack;

  constructor(private http: IogHttpService) {}
  getMeterBySerial(serial) {
    const options = {
      headers: new HttpHeaders({ "x-priority-request": "x-priority-request" }),
      priority: "high",
    };
    return this.http
      .get(MetersService.url + "/meters/" + serial, options)
      .map((res) => res.valueOf());
  }

  getMetersComissioned() {
    return this.http
      .get(MetersService.url + "/meters/commissioned-filter-no-page")
      .map((res) => res.valueOf());
  }

  getAllMeters() {
    return this.http
      .get(MetersService.url + "/meters/get-all-no-page")
      .map((response) => response.valueOf());
  }

  getMetersComissionedOrNotComissioned(
    comissioned = true,
    sortOrder: string = "updatedAt-desc",
    searchString: string = "",
    page: number = 1,
    pageSize: number = 15,
    startDate = "",
    endDate = "",
    modemSignalQuality = ""
  ) {
    return this.http
      .get(
        MetersService.url +
          `/meters?sortOrder=${sortOrder}&pageSize=${pageSize}&searchString=${searchString}&page=${page}&startDateTime=${startDate}&endDateTime=${endDate}&comissioned=${comissioned}&modemSignalQuality=${modemSignalQuality}`
      )
      .map((res) => res.valueOf());
  }

  getTransformersAvalaibleMeter() {
    return this.http.get(
      MetersService.url + `/meters/without-transformer-no-pagination`
    );
  }

  getManufactures() {
    return this.http
      .get(MetersService.url + "/manufacturers")
      .map((response) => response.valueOf());
  }
  getMetersNotComissioned() {
    return this.http
      .get(
        MetersService.url + "/meters/not-comissioned?sortOrder=updatedAt-desc"
      )
      .map((res) => res.valueOf());
  }

  getMeters(
    sortOrder: string = "updatedAt-desc",
    searchString: string = "",
    page: number = 1,
    pageSize: number = 15,
    startDate = "",
    endDate = "",
    modemSignalQuality = ""
  ): Observable<IPaginationResponseDTO> {
    return this.http.get<IPaginationResponseDTO>(
      MetersService.url +
        `/meters?sortOrder=${sortOrder}&pageSize=${pageSize}&searchString=${searchString}&page=${page}&startDateTime=${startDate}&endDateTime=${endDate}&modemSignalQuality=${modemSignalQuality}`
    );
  }

  getEventLogBySerial(
    serial: string,
    pageSize: number = 15,
    sortOrder: string = "updatedAt-desc",
    page: number = 1,
    startDateTime: string = "",
    endDateTime: string = "",
    searchString: string = ""
  ) {
    return this.http
      .get(
        `${MetersService.url}/logs/${serial}?pageSize=${pageSize}&sortOrder=${sortOrder}&page=${page}&startDateTime=${startDateTime}&endDateTime=${endDateTime}&searchString=${searchString}`
      )
      .map((res) => res.valueOf());
  }

  getMetersComissionedWithoutPagination() {
    return this.http
      .get(`${MetersService.url}/meters/commissioned-filter-no-page`)
      .map((response) => response.valueOf());
  }

  getMetersNotComissionedWithoutPagination() {
    return this.http
      .get(`${MetersService.url}/meters/not-comissioned-no-page`)
      .map((response) => response.valueOf());
  }

  getMeterEnergyByDate(serial, startDate, endDate) {
    return this.http
      .get(
        `${MetersService.url}/meters/active-energy-date/${serial}/${startDate}/${endDate}`
      )
      .map((res) => res.valueOf());
  }

  getMetersStatusStatistic(
    comissioned: boolean,
    tags: string[] = [],
    isSmi: boolean = null
  ): Observable<IOnlineEstatisticDTO> {
    const url = `${MetersService.url}/meters/online-meters-percentage?
      ${
        comissioned == true || comissioned == false
          ? `comissioned=${comissioned}&`
          : ""
      }
      ${
        tags.length
          ? tags
              .map((tag) => `visibleId=${tag}&`)
              .join()
              .replace(/\,/g, "")
          : ""
      }
      ${isSmi == true || isSmi == false ? `isSmi=${isSmi}` : ""}`.replace(
      /\s/g,
      ""
    );
    return this.http.get<IOnlineEstatisticDTO>(url);
  }

  getMetersComissionedPercentage(
    tags: string[] = [],
    isSmi: boolean = null
  ): Observable<IMetersComissionedStatisticDTO> {
    const url = `${MetersService.url}/meters/comissioned-meters-percentage/?
      ${
        tags.length
          ? tags
              .map((tag) => `visibleId=${tag}&`)
              .join()
              .replace(/\,/g, "")
          : ""
      }
      ${isSmi == true || isSmi == false ? `isSmi=${isSmi}` : ""}
      `.replace(/\s/g, "");
    return this.http.get<IMetersComissionedStatisticDTO>(url);
  }

  getMetersUnregistred() {
    return this.http
      .get(`${MetersService.url}/smi-not-registered`)
      .map((response) => response.valueOf());
  }

  getMetersWithPagination(
    sortOrder: string = "updatedAt-desc",
    searchString: string = "",
    page: number = 1,
    pageSize: number = 15,
    startDate = "",
    endDate = ""
  ) {
    return this.http
      .get(
        MetersService.url +
          `/meters?sortOrder=${sortOrder}&pageSize=${pageSize}&searchString=${searchString}&page=${page}&startDateTime=${startDate}&endDateTime=${endDate}`
      )
      .map((response) => response.valueOf());
  }

  getRatesType() {
    return this.http
      .get(MetersService.url + "/rate-types")
      .map((response) => response.valueOf());
  }

  // CRUD MODELS
  getMetersModels() {
    return this.http
      .get(MetersService.url + "/meter-models")
      .map((response) => response.valueOf());
  }

  createMeterModel(meterModel: MeterModelDTO) {
    return this.http.post(MetersService.url + "/meter-models", meterModel);
  }

  deleteMeterModel(meterModel: MeterModelDTO) {
    return this.http.delete(
      MetersService.url + `/meter-models/${meterModel.name}`
    );
  }

  updateModel(model: MeterModelDTO) {
    return this.http.put(MetersService.url + "/meter-models", model);
  }

  // CRUD METERS
    saveMeter(deviceDTO: MeterDTO) {
    let device: MeterDTO = deviceDTO;
    device.serial = String(device.serial).trim();
    return this.http.post(MetersService.url + "/meters", device);
  }

  deleteMeter(serial) {
    return this.http.delete(MetersService.url + `/meters/${serial}`);
  }

  updateMeter(deviceDTO: MeterDTO) {
    let device: MeterDTO = deviceDTO;
    device.serial = String(device.serial).trim();
    return this.http.put(MetersService.url + "/meters", device);
  }

  uploadMetersByFile(meters: any[]) {
    return this.http.post(MetersService.url + "/meters/batch-import", meters);
  }

  massEdity(meters: any[]) {
    return this.http.put(
      MetersService.url + "/meters/mass-meters-edit",
      meters
    );
  }

  metersByStatisticFilters({
    searchString = null,
    comissioned = null,
    online = null,
    isSmi = null,
    tags = [],
    page = 1,
    pageSize = 15,
  }): Observable<PaginationResponseDTO> {
    const params = `/meters/meters-by-statistic-filters?
      ${searchString ? `searchString=${searchString}&` : ""}
      ${
        comissioned == true || comissioned == false
          ? `comissioned=${comissioned}&`
          : ""
      }
      ${online != null ? `online=${online}&` : ""}
      ${isSmi != null ? `isSmi=${isSmi}&` : ""}
      ${
        tags.length
          ? tags
              .map((tag) => `visiblesIds=${tag}&`)
              .join()
              .replace(/\,/g, "")
          : ""
      }
      page=${page}&pageSize=${pageSize}
    `.replace(/\s/g, "");

    return this.http.get<PaginationResponseDTO>(MetersService.url + params);
  }
}
