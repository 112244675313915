import * as moment from "moment";
import { environment } from "src/environments/environment";

function getLang() {
  const currentLanguage = localStorage.getItem(environment.currentLanguage);
  return currentLanguage || navigator.language;
}

const ONEHOURINMINUTES = 60;
const KEYREGEXREPLACE = /(?<=[A-Za-z0-9]{3})[A-Za-z0-9](?=[A-Za-z0-9]{3})/gm;
const DEFAULT_DATE = '0001-01-01T00:00:00Z';
export class Utils {
  static isMobile() {
    return window && window.matchMedia("(max-width: 767px)").matches;
  }

  static ngbDateToDate(ngbDate: { month; day; year }) {
    if (!ngbDate) {
      return null;
    }
    return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year}`);
  }

  static dateToNgbDate(date: Date) {
    if (!date) {
      return null;
    }
    date = new Date(date);
    return {
      month: date.getMonth() + 1,
      day: date.getDate(),
      year: date.getFullYear(),
    };
  }

  static scrollToTop(selector: string) {
    if (document) {
      const element = document.querySelector(selector);
      element.scrollTop = 0;
    }
  }

  static genId() {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      text += possible.charAt(
        Math.floor(new Date().getTime() * possible.length)
      );
    }
    return text;
  }

  static getLang() {
    const lang = getLang();
    return lang;
  }

  static pipeDateTime(dateTime: Date): string {
    if (dateTime && dateTime.toString() == DEFAULT_DATE)
      return 'Nunca Comunicou ou Data Invalida';
    return moment(dateTime).utc(false).locale(Utils.getLang()).format('l LTS')

  }

  static getDate(dateTime) {
    return moment(dateTime).locale(Utils.getLang()).format('l')
  }

  static getDateToCreateDate(dateTime: Date) {
    return moment(dateTime)
      .utc(false)
      .format("YYYY/MM/DD");
  }

  static getDateByFilter(dateTime: Date) {
    return moment(dateTime)
      .utc(false)
      .format("YYYY-MM-DD");
  }

  static handleDayDate(dateTime: Date, day: string) {
    switch (day) {
      case "D":
        return moment(dateTime).utc(false).format("DD [de] MMMM [de] YYYY");
      case "D+1":
        return moment(dateTime)
          .utc(false)
          .add(1, "day")
          .format("DD [de] MMMM [de] YYYY");
      case "D-1":
        return moment(dateTime)
          .utc(false)
          .subtract(1, "day")
          .format("DD [de] MMMM [de] YYYY");
      case "D-2":
        return moment(dateTime)
          .utc(false)
          .subtract(2, "day")
          .format("DD [de] MMMM [de] YYYY");
    }
  }

  static formateDateTime(date: Date) {
    if (date.toString() === DEFAULT_DATE) return 'Nunca Comunicou ou Data Invalida';
    return moment(date).format("DD-MMMM-YYYY HH:mm:ss");
  }

  static getTimeInMinutes(timeToConvert: any): number {
    return timeToConvert.hour * ONEHOURINMINUTES + timeToConvert.minute;
  }

  static convertMinutesInHour(timeInMinutes: number): string {
    let hour: any = 0;
    let formatedDate: any = "";
    if (timeInMinutes >= 60) {
      hour = Math.floor(timeInMinutes / ONEHOURINMINUTES).toFixed();
      formatedDate += `${hour}H:`;
    }
    const minutes = timeInMinutes % ONEHOURINMINUTES;
    formatedDate += `${minutes}M`;
    return formatedDate;
  }

  static removePrefixAndSufix(serial: any): string {
    const stringSerial = serial.toString();
    return stringSerial.substr(2, stringSerial.length - 3);
  }

  static encryptKeys(key: string): string {
    return key.replace(KEYREGEXREPLACE, "*");
  }

  static decimalToHexString(intNumber): string {
    return "0x" + intNumber.toString(16).toUpperCase();
  }
}
