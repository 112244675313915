import {
  Component,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash';

// Modelos
import {
  TicketDTO,
  TicketsStatus,
  CommandType,
  Status,
  StatusCommand,
} from 'src/app/core/models/TicketsDTO';
import { PaginationResponseDTO } from 'src/app/core/models/PaginationResponseDTO';
import { CommandDTO, CommandStatus } from 'src/app/core/models/CommandDTO';

import { Utils } from 'src/app/shared/utils';
import { TicketsService } from '../../services/tickets.service';
import { HandleErrorService } from '../../services/handle-error.service';
import { MessageService } from '../../services/message.service';
import { DlmsService } from '../../services/dmls-service/services/Implementacao/DlmsService';
import { DlmsDriverService } from '../../services/dmls-service/services/Implementacao/DriverService';
import { MeterDTO } from 'src/app/core/models/MeterDTO';
import { TranslateInCodeService } from '../../services/translate-in-code.service';
import { EnumMapperDTO } from 'src/app/core/models/EnumMapperDTO';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-tickets-table',
  templateUrl: 'ticket-table.component.html',
  styleUrls: ['./ticket-table.component.scss'],
})
export class TicketTableComponent implements OnInit {
  @Input() tickets: TicketDTO[];
  @Input() page: PaginationResponseDTO;
  @Input() isLoading: boolean;
  @Input() isSMC = true;
  @Input() isReloading = false;
  @Input() serial: string = null;
  @Input() isSchedulingTables = false;
  @Input() isAbsolute = false;

  @Output() sendType = new EventEmitter();
  @Output() filter = new EventEmitter();
  @Output() reload = new EventEmitter();

  public canceledTicket: TicketDTO;
  public progressTicket: TicketDTO;
  public isCanceling = false;

  @ViewChild('ngxTable', { static: false }) table: any;

  ticketDTO = TicketDTO;
  Utils = Utils;
  Status = Status;
  CommandType = CommandType;
  TicketStatus = TicketsStatus;
  public ticket: TicketDTO;
  public response = null;
  public ticketAnswer: TicketDTO = null;
  currentCommand = null;
  public Commands = CommandStatus;
  public CommandDTO = CommandDTO;
  commandsNumber = Math.ceil(Object.keys(CommandType).length / 2) - 1;

  public startDate = '';
  public endDate = '';
  public sortOrder = this.isSchedulingTables ? 'ticketCreationDate-desc' : 'updatedAt-desc';
  public searchString = '';
  public currentPage = 0;
  public pageSize = 15;
  public filters = {
    pageSize: this.pageSize,
    page: this.currentPage,
    begin: this.startDate,
    end: this.endDate,
    sortOrder: this.sortOrder,
    searchString: this.searchString,
  };

  EnumMapperDTO: EnumMapperDTO[];
  constructor(
    private modalService: NgbModal,
    private ticketsService: TicketsService,
    private handleService: HandleErrorService,
    private messageService: MessageService,
    private _translateInCode: TranslateInCodeService,
    private _translateService: TranslateService,
    private driverService: DlmsDriverService,
  ) {
    this.searchByID = debounce(this.searchByID, 500);

  }
  async ngOnInit() {
    await this.getTranslate();
    this._translateService.onLangChange.subscribe(async () => {
      await this.getTranslate();
    });
  }

  parseJson(json) {
    if (!json) { return; }
    try {
      return JSON.parse(json);
    } catch (error) {
      return json;
    }
  }

  verifyIfSucess(commandStatus) {
    return commandStatus === StatusCommand.SUCESS;
  }

  parseDecimal(number) {
    return number.toFixed(3);
  }

  accountantStatus(status) {
    return MeterDTO.getRelayStatusValue(status);
  }

  accountantStatusClass(status) {
    return MeterDTO.getRelayStatusClass({ accountantStatus: status});
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  async getTranslate() {
    this
      ._translateInCode
      .getTranslateEnunsInArray('Tickets.Enuns', [TicketsStatus, StatusCommand, CommandType])
      .then((res) => {
        this.EnumMapperDTO = res;
      });
  }



  processMetersList(row: TicketDTO): string {
    if (row.statusCommand === StatusCommand.SUCESS && !this.isAbsolute) {
      const response: number[] = row.commandTickets[0].answer.trimRight().split(' ').map(num => parseInt(num, 16));
      const meters: MeterDTO[] = this.driverService.processMessage(response, row);
      let metersList = '';
      meters.forEach(meter => {
        metersList += 'P' + meter.position + ':' + meter.serial + '; ';
      });
      return metersList;
    }
    return row.commandTickets[0].answer;
  }



  filterTickets() {
    this.sendType.emit(this.currentCommand);
    this.close();
  }

  openModal(content: any, ticket?: TicketDTO) {
    if (ticket) {
      this.canceledTicket = ticket;
    }
    this.modalService.open(content, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  openTicketAnswer(content: any, row: TicketDTO) {
    this.ticketAnswer = row;
    this.response = JSON.parse(row.commandTickets[0].pdu);
    this.modalService.open(content, {
      size: 'xl',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      windowClass: 'modal-xl',
    });
  }

  close() {
    this.canceledTicket = null;
    this.ticketAnswer = null;
    this.response = null;
    this.modalService.dismissAll();
  }

  selectCommand(index) {
    this.currentCommand = index;
  }

  cancelTicket() {
    this.isCanceling = true;
    this.ticketsService
      .cancelTicket(this.canceledTicket.ticketId)
      .toPromise()
      .then((response) => {
        this.messageService.showSuccess(
          'Pedido enviado',
          'Cancelamento executado com sucesso!'
        );
        this.isCanceling = false;
        this.close();
      })
      .catch((error) => {
        this.isCanceling = false;
        this.handleService.handle(error);
      });
  }

  onSort(value) {
    const { sorts } = value;
    this.sortOrder = `${sorts[0].prop}-${sorts[0].dir}`;
    this.filters['sortOrder'] = this.sortOrder;
    this.currentPage = 0;
    this.filters['page'] = this.currentPage;
    this.emiteValues();
  }

  filterByPage(value) {
    const { page } = value;
    this.currentPage = page ? page - 1 : 0;
    this.filters['page'] = this.currentPage;
    this.emiteValues();
  }

  filterByDate() {
    this.filters['page'] = 0;
    this.filters['begin'] = this.startDate;
    this.filters['end'] = this.endDate;
    this.emiteValues();
    this.close();
  }

  changeSizeValue(value) {
    this.currentPage = 0;
    this.filters['page'] = this.currentPage;
    this.pageSize = value;
    this.filters['pageSize'] = this.pageSize;
    this.emiteValues();
  }

  removeFilter() {
    this.reseteValues();
    this.emiteValues();
  }

  searchByID(value) {
    this.searchString = value;
    this.filters.searchString = this.searchString;
    this.emiteValues();
  }

  reloadTickets() {
    this.reload.emit();
  }

  private reseteValues() {
    this.sortOrder = this.isSchedulingTables ? 'ticketCreationDate-desc' : 'updatedAt-desc';
    this.startDate = '';
    this.endDate = '';
    this.searchString = '';
    this.pageSize = this.page.pageSize;
    this.currentPage = 0;
    this.filters['page'] = this.currentPage;
    this.filters['end'] = this.endDate;
    this.filters['begin'] = this.startDate;
    this.filters['sortOrd'] = this.sortOrder;
    this.filters.searchString = this.searchString;
    this.close();
    this.emiteValues();
  }

  private emiteValues() {
    this.filter.emit(this.filters);
  }

}



