import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { HandleErrorService } from "../../services/handle-error.service";

import cronstrue from 'cronstrue/i18n';
import { Utils } from "../../utils";
import { ScheduleCommandDTO, ScheduleCommandType } from "src/app/core/models/ScheduleCommandDTO";
import { GroupServices } from "../../services/groups.service";
import { GroupDTO } from "src/app/core/models/GroupDTO";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { TranslateService } from "@ngx-translate/core";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";

const ONE_HOUR_IN_MINUTES = 60;
const ONE_DAY_IN_MINUTES = ONE_HOUR_IN_MINUTES * 24;
@Component({
    selector: 'app-add-routine-step',
    styleUrls: ['./add-routine-step.component.scss'],
    templateUrl: './add-routine-step.component.html'
})
export class AddRoutineStepComponent implements OnInit {
    public groups: GroupDTO[] = [];
    public commands = Object.values(ScheduleCommandType);

    @Input() isLoading: boolean = false;
    @Input() scheduleToUpdate;

    public isLoadingGroups: boolean = false;
    public comandTranslate: string = '';

    public CommandDTO = ScheduleCommandDTO;
    public ScheduleCommandType = ScheduleCommandType;
    public selectedCommand: ScheduleCommandType;
    public ScheduleCommandWithAditionalsParams = [
        ScheduleCommandType.RecoverMassMemorySmc, ScheduleCommandType.RecoverMassMemorySmi,
        ScheduleCommandType.NicResetSmi, ScheduleCommandType.NicResetSmc,
        ScheduleCommandType.RecoverEnergySmc, ScheduleCommandType.RecoverQeeIndicator,
        ScheduleCommandType.RecoverEnergySmi
    ];
    public selectedGroup: GroupDTO = null;

    public description: string = '';

    public minute: number = 0;
    public hour: number = 0;
    public days: number = 0;

    //recover mass memory params
    public startDate = null;
    public endDate = null;
    public quantity = null;
    public intervalInMinutes: NgbTimeStruct = { hour: 0, minute: 10, second: 0 };
    public minuteStep = 10;

    private scheduleDuration: number = this.convertTimeToMinutes();

    //nic reset params
    public resetNickTimesTamp = 10;
    public selectedResetNickTimesTamp = 0;

    Utils = Utils;

    //Forms
    public commandSettingsForm: FormGroup;
    public cronSettingsForm: FormGroup;

    public cron: string = '';

    @Output() schedule: EventEmitter<any> = new EventEmitter<any>();
    @Output() scheduleToUpateEm: EventEmitter<any> = new EventEmitter<any>();

    EnumMapperDTO: EnumMapperDTO;
    constructor(
        private groupServices: GroupServices,
        private handleError: HandleErrorService,
        private formBuilder: FormBuilder,
        private translateInCode: TranslateInCodeService,
        private translate: TranslateService
    ) { }

    async ngOnInit() {
        await this.getTranslate();
        this.translate.onLangChange.subscribe(e => this.getTranslate());
        this.getGroups();
        this.buildCommandSettingsForm();
        this.buildCronSettingsForm();
    }

    getCron(event) {
        this.cron = event;
    }

    async getTranslate() {
        this.EnumMapperDTO = await this
            .translateInCode
            .getTranslate('Schedule.ScheduleCommandType', ScheduleCommandType);
    }

    parseScheduleObjectToUpdate() {
        if (this.scheduleToUpdate) {
            try {
                const days = Math.floor(this.scheduleToUpdate.duration / ONE_DAY_IN_MINUTES)
                const hoursToSplit = this.scheduleToUpdate.duration % ONE_DAY_IN_MINUTES;
                const hours = Math.floor(hoursToSplit / ONE_HOUR_IN_MINUTES)
                const minutes = hoursToSplit % ONE_HOUR_IN_MINUTES;
                this.selectedCommand = this.scheduleToUpdate.scheduleCommandType;
                this.selectedGroup = this.groups.filter(group => group.visibleId == this.scheduleToUpdate.groupVisibleId)[0];
                this.days = days;
                this.hour = hours;
                this.minute = minutes;
                this.description = this.scheduleToUpdate.description;
                this.cron = this.scheduleToUpdate.cron;
                this.getCommandsToUpdate();
            } catch (error) {
            }
        }
    }

    getCommandsToUpdate() {
        switch (this.selectedCommand) {
            case ScheduleCommandType.NicResetSmc:
                this.selectedResetNickTimesTamp = this.scheduleToUpdate.nicResetInput.seconds;
                break;
            case ScheduleCommandType.NicResetSmi:
                this.selectedResetNickTimesTamp = this.scheduleToUpdate.nicResetInput.seconds;
                break;
            case ScheduleCommandType.RecoverEnergySmc:
                this.quantity = this.scheduleToUpdate.recoverEnergySmcInput.quantityOfLastMeasures;
                break;
            case ScheduleCommandType.RecoverQeeIndicator:
                this.quantity = this.scheduleToUpdate.recoverQeeIndicatorInput.quantityOfLastMeasures;
                break;
            case ScheduleCommandType.RecoverMassMemorySmc:
                this.quantity = this.scheduleToUpdate.recoverMassMemorySmcInput.quantityOfLastMeasures;
                break;
            case ScheduleCommandType.RecoverMassMemorySmi:
                this.startDate = Utils.getDateByFilter(this.scheduleToUpdate.recoverMassMemorySmiInput.initialDatetime);
                this.endDate = Utils.getDateByFilter(this.scheduleToUpdate.recoverMassMemorySmiInput.finishDatetime);
                this.intervalInMinutes = {
                    hour: Math.floor(this.scheduleToUpdate.recoverMassMemorySmiInput.IntervalInMinutes / 60),
                    minute: this.scheduleToUpdate.recoverMassMemorySmiInput.IntervalInMinutes % 60,
                    second: 0
                }
                break;
            case ScheduleCommandType.RecoverEnergySmi:
                this.startDate = Utils.getDateByFilter(this.scheduleToUpdate.recoverEnergySmiInput.initialDatetime);
                this.endDate = Utils.getDateByFilter(this.scheduleToUpdate.recoverEnergySmiInput.finishDatetime);
                break;
            default:
                return null;
        }
    }

    getTranslatedCommand() {
        return this.CommandDTO.getScheduleCommand((this.selectedCommand));
    }

    addHour(hour) {
        return ('0' + hour).slice(-2);
    }

    formatCron(value) {
        if (value) {
            let lang = Utils.getLang()
            lang = lang == 'pt-BR' ? lang.replace('-', '_') : lang;
            return cronstrue.toString(value, { locale: lang });
        }
    }

    selectGroup(visibleId) {
        this.selectedGroup = this.groups.filter(group => group.visibleId == visibleId)[0];
    }

    selectCommand(command) {
        this.selectedCommand = command;
        this.buildCommandSettingsForm();
    }

    getGroups() {
        this.isLoadingGroups = true;
        this
            .groupServices
            .getAll()
            .toPromise()
            .then(response => {
                this.groups = response;
                this.parseScheduleObjectToUpdate();
                this.buildCommandSettingsForm();
                this.isLoadingGroups = false;
            }).catch(error => {
                this.handleError.handle(error);
                this.isLoadingGroups = false;
            });
    }

    updateSchedule() {
        const data = this.buildScheduleObject();
        Object.assign(data, { Id: this.scheduleToUpdate.id });
        this.scheduleToUpateEm.emit(data);
    }

    createSchedule() {
        this.schedule.emit(this.buildScheduleObject());
    }

    private buildScheduleObject() {
        return {
            GroupVisibleId: this.selectedGroup.visibleId,
            Cron: this.cron,
            Description: this.description,
            ScheduleCommandType: this.selectedCommand,
            duration: this.convertTimeToMinutes(),
            ...this.getParamsCommandPost()
        }
    }

    private getParamsCommandPost() {
        switch ((this.selectedCommand)) {
            case ScheduleCommandType.NicResetSmc:
                return { NicResetInput: this.getParamsCommand() };
            case ScheduleCommandType.NicResetSmi:
                return { NicResetInput: this.getParamsCommand() };
            case ScheduleCommandType.RecoverEnergySmc:
                return { RecoverEnergySmiInput: this.getParamsCommand() };
            case ScheduleCommandType.RecoverQeeIndicator:
                return { RecoverQeeIndicatorInput: this.getParamsCommand() };
            case ScheduleCommandType.RecoverMassMemorySmc:
                return { RecoverMassMemorySmcInput: this.getParamsCommand() };
            case ScheduleCommandType.RecoverMassMemorySmi:
                return { RecoverMassMemorySmiInput: this.getParamsCommand() };
            case ScheduleCommandType.RecoverEnergySmi:
                return { RecoverEnergySmiInput: this.getParamsCommand() };
            default:
                return {};
        }
    }

    private getParamsCommand() {
        const MINUTE = 60;
        switch (this.selectedCommand) {
            case ScheduleCommandType.NicResetSmc:
                return { Seconds: this.resetNickTimesTamp - this.selectedResetNickTimesTamp };
            case ScheduleCommandType.NicResetSmi:
                return { Seconds: this.resetNickTimesTamp - this.selectedResetNickTimesTamp };
            case ScheduleCommandType.RecoverEnergySmc:
                return { QuantityOfLastMeasures: this.quantity };
            case ScheduleCommandType.RecoverQeeIndicator:
                return { QuantityOfLastMeasures: this.quantity };
            case ScheduleCommandType.RecoverMassMemorySmc:
                return { QuantityOfLastMeasures: this.quantity };
            case ScheduleCommandType.RecoverMassMemorySmi:
                return { InitialDatetime: this.startDate, FinishDatetime: this.endDate, IntervalInMinutes: this.intervalInMinutes.hour * MINUTE + this.intervalInMinutes.minute };
            case ScheduleCommandType.RecoverEnergySmi:
                return { InitialDatetime: this.startDate, FinishDatetime: this.endDate };
            default:
                return null;
        }
    }

    private buildCommandSettingsForm() {
        this.commandSettingsForm = this.formBuilder.group({
            group: new FormControl(this.selectedGroup ? this.selectedGroup.visibleId : 0, Validators.required),
            commands: [this.selectedCommand, Validators.required],
            interval: [this.intervalInMinutes],
            endDate: [this.endDate],
            startDate: [this.startDate],
            quantity: [this.quantity || 0],
            description: [this.description],
            days: [this.days],
            hour: [this.hour],
            minute: [this.minute],
        });
    }

    private buildCronSettingsForm() {
        this.cronSettingsForm = this.formBuilder.group({
            cronForm: [Validators.required]
        });
    }

    public commandsWithParams() {
        return this.ScheduleCommandWithAditionalsParams.includes(this.selectedCommand);
    }

    public convertTimeToMinutes(): number {
        if (this.days === 0 && this.hour === 0 && this.minute === 0) {
            this.comandTranslate = 'O comando será executado em todos os dispositivos no mesmo instante podendo causar congestionamento na rede !'
        } else {
            this.comandTranslate = `A distribuição da execução do comando nos dispositivos será em ${this.days} dia(s), ${this.hour} hora(s) e ${this.minute} minuto(s)`;
        }
        return this.days * ONE_DAY_IN_MINUTES + this.hour * ONE_HOUR_IN_MINUTES + this.minute;
    }
}