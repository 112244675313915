import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { QualityIndicatorDTO } from "src/app/core/models/QualityIndicatorDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { Utils } from "src/app/shared/utils";
import { MeterDTO } from "src/app/core/models/MeterDTO";
import { QualityIndicatorsServices } from "../../services/quality-indicators.service";
import { HandleErrorService } from "../../services/handle-error.service";
import { MessageService } from "../../services/message.service";
import { ExportsDataServices } from "../../services/export-data.service";

export interface MonthlyQEEResponse {
  serial: string;
  dateTimeInitial: string;
  dateTimeFinal: string;
  drp: string;
  drc: string;
  count: number;
  meterActivated: boolean;
  updatedAt: string;
  dtt95: string;
  fd95: string;
}
@Component({
  selector: "app-quality-indicators",
  templateUrl: "./quality-indicators.component.html",
  styleUrls: ["./quality-indicators.component.scss"],
})
export class QualityIndicatorsComponent implements OnInit {
  @Input() qualityIndicators: QualityIndicatorDTO[];
  @Input() isLoading = true;
  @Input() page: PaginationResponseDTO;
  @Input() meter: MeterDTO = null;
  @Input() isReloading = false;

  @Output() reload = new EventEmitter();
  @Output() filter = new EventEmitter();
  @Output() getCurrentPage = new EventEmitter();

  Utils = Utils;
  QualityIndicatorDTO = QualityIndicatorDTO;
  monthRefValue = null;

  public startDate = "";
  public endDate = "";
  public sortOrder = "datetimeFinal-desc";
  public pageSize = 15;
  public currentPage = 0;
  public maxMonth = null;
  public isLoadingAverage = false;
  public isDownloadingQEE = false;

  drpAverage = null;
  drcAverage = null;
  dtt95Average = null;
  fd95Average = null;
  initalDateTime = null;
  endDateDateTime = null;

  public filters = {
    page: this.currentPage,
    begin: this.startDate,
    pageSize: this.pageSize,
    end: this.endDate,
    sortOrder: this.sortOrder,
  };

  constructor(
    private modalService: NgbModal,
    private qeeServices: QualityIndicatorsServices,
    private handleError: HandleErrorService,
    private messageService: MessageService,
    private _exportDataServices: ExportsDataServices,
  ) { }

  ngOnInit() {
    this.maxMonth = `${new Date().getFullYear().toString()}-${(
      "0" + new Date().getMonth()
    ).slice(-2)}`;
  }

  openModal(content) {
    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  reloadData() {
    this.reload.emit();
  }

  filterBydate() {
    this.filters.end = this.endDate;
    this.filters.begin = this.startDate;
    this.currentPage = 0;
    this.filters["page"] = this.currentPage;
    this.filterEvent();
    this.close();
  }

  filterQualityIndicators(page) {
    this.currentPage = page.page - 1;
    this.filters.page = this.currentPage;
    this.filterEvent();
  }

  onSort(value) {
    const sort = value.sorts[0];
    this.sortOrder = `${sort.prop}-${sort.dir}`;
    this.currentPage = 1;
    this.filters["sortOrder"] = this.sortOrder;
    this.filterEvent();
  }

  changeSizeValue(value) {
    this.pageSize = value;
    this.currentPage = 0;
    this.sortOrder = "datetimeFinal-desc";
    this.filters.sortOrder = this.sortOrder;
    this.filters["page"] = this.currentPage;
    this.filters["pageSize"] = this.pageSize;
    this.filterEvent();
  }

  filterEvent() {
    this.filter.emit(this.filters);
  }
  finishOperation() {
    this.close();
    this.resetValues();
  }
  removeFilter() {
    this.sortOrder = "";
    this.startDate = "";
    this.endDate = "";
    this.pageSize = 15;
    this.currentPage = 0;
    this.filters["page"] = this.currentPage;
    this.filters["end"] = this.endDate;
    this.filters["begin"] = this.startDate;
    this.filters["sortOrd"] = this.sortOrder;
    this.filters["pageSize"] = this.pageSize;
    this.filterEvent();
    this.finishOperation();
  }

  requestMonthlyQEE() {
    this.isLoadingAverage = true;
    this.qeeServices
      .requestMonthlyQEE(this.meter.serial, this.monthRefValue)
      .toPromise()
      .then((response: MonthlyQEEResponse) => {
        this.messageService.showSuccess(
          "Requisição Enviada",
          "Média mênsal solicitado com sucesso!"
        );
        this.drpAverage = response.drp;
        this.drcAverage = response.drc;
        this.dtt95Average = response.dtt95;
        this.fd95Average = response.fd95;
        this.initalDateTime = response.dateTimeInitial;
        this.endDateDateTime = response.dateTimeFinal;
        this.isLoadingAverage = false;
      })
      .catch((error) => {
        this.handleError.handle(error);
        this.isLoadingAverage = false;
      });
  }

  saveQEE() {
    this.isDownloadingQEE = true;
    this._exportDataServices.exportMeterQee(this.meter.serial).toPromise()
      .then(response => {
        const dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute('download', `Parâmetros de Qualidade do medidor ${this.meter.serial}.csv`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.isDownloadingQEE = false;
        this.close();
      })
      .catch(error => {
        this.isDownloadingQEE = false;
        this.handleError.handle(error);
      });

  }

  private resetValues() {
    this.startDate = "";
    this.endDate = "";
  }

  private close() {
    this.modalService.dismissAll();
  }
}
