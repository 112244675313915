export interface IPaginationResponseDTO {
  page: number;
  pageSize: number;
  numberOfPages: number;
  numberOfItems: number;
  listOfItems: any[];
  pagesSizeOptions: number[];
}

export class PaginationResponseDTO implements IPaginationResponseDTO {
  public page = 0;
  public pageSize = 15;
  public numberOfPages = 0;
  public numberOfItems = 0;
  public listOfItems: any[];
  public pagesSizeOptions: number[] = [10, 12, 15, 18, 20, 25];
  public totalCount = 0;
  public data: any[];
}
