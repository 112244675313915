import { Injectable, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";

export interface IMenuItem {
  id?: string;
  title?: string;
  description?: string;
  type: string; // Possible values: link/dropDown/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  active?: boolean;
}

export interface IChildItem {
  id?: string;
  parentId?: string;
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
}


interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

export interface MetersMenu {
  title: string;
  allMeters: string;
  comissioned: string;
  notComissioned: string;
  notRegistred: string;
  alarms: string;
}
export interface IMetersCrud {
  title: string;
  all: string;
  fileUpload: string;
  models: string;
  registers: string;
}

export interface ISMCCrud {
  list: string;
  File: string;
  models: string;
}

export interface GroupMenu {
  title: string;
  searchTags: string;
  listTags: string;
  registered: string;
}

export interface CrudMenu {
  title: string;
  meters: IMetersCrud;
  keyRegisters: string;
  smc: ISMCCrud;
  transformers: ITransformersCrud;
  rateTypes: string;
  firmware: string;

}

export interface ITransformersCrud {
  title: string;
  areas: string;
  regions: string;
}

export interface IBalance {
  title: string;
  area: string;
  region: string;
  transformer: string;
}
export interface ScheduleMenu {
  title: string;
  list: string;
  newCommand: string;
  systemCommands: string;
}

export interface clusterComamand {
  title: string;
  readings: string;
  relay: string;
}

export interface ITicketsMenu {
  Title: string;
}

export interface IAuditMenu {
  Title: string;
  Installation: string;
}

export interface IMenuTransaltedItens {
  Meters: MetersMenu;
  App: string;
  UserControl: string;
  ServicesNotes: string;
  DashBoard: IDashBoard;
  Crud: CrudMenu;
  Balance: IBalance;
  clusterCommand: clusterComamand;
  schedulesCommand: ScheduleMenu;
  group: GroupMenu;
  Tickets: ITicketsMenu;
  Audit: IAuditMenu;
}
export interface IDashBoard {
  Alarms: string;
  Readings: string;
  Title: string;
}

class TranslatedMenu implements IMenuTransaltedItens {
  Tickets: ITicketsMenu;
  group: GroupMenu;
  schedulesCommand: ScheduleMenu;
  Crud: CrudMenu;
  DashBoard: IDashBoard;
  ServicesNotes: string;
  UserControl: string;
  App: string;
  Meters: MetersMenu;
  Balance: IBalance;
  clusterCommand: clusterComamand;
  Audit: IAuditMenu;

}

@Injectable({
  providedIn: "root",
})
export class NavigationService implements OnInit {
  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false,
  };

  translateMenu: IMenuTransaltedItens = new TranslatedMenu();



  defaultMenu: IMenuItem[] = [
    {
      name: "DashBoard",
      type: "dropDown",
      icon: 'i-Statistic',
      sub: [
        {
          name: "Leituras",
          type: "link",
          icon: 'i-Monitor-5',
          state: '/dashboard/leituras',
        },
        {
          name: "Alarmes",
          type: "link",
          icon: 'i-Bell',
          state: '/dashboard/alarmes',
        }
      ]
    },
    {
      name: 'Medidores',
      type: "dropDown",
      icon: "i-Dashboard",
      sub: [
        {
          icon: "i-Data-Save",
          name: "Todos",
          state: "/medidores/todos",
          type: "link",
        },
        {
          icon: "i-Data-Save",
          name: "Comissionados",
          state: "/medidores/comissionados",
          type: "link",
        },
        {
          icon: "i-Data-Block",
          name: "Não Comissionados",
          state: "/medidores/nao-comissionados",
          type: "link",
        },
        {
          icon: "i-Close-Window",
          name: "Não Registrados",
          state: "/medidores/nao-registrados",
          type: "link",
        },
        {
          icon: "i-Bell",
          name: "Alarmes",
          state: "/medidores/alarmes",
          type: "link",
        },
      ],
    },
    {
      name: "SMC's",
      type: "dropDown",
      icon: "i-Posterous",
      sub: [
        {
          icon: "i-Data-Save",
          name: "Comissionados",
          state: `/smcs/comissionados`,
          type: "link",
        },
        {
          icon: "i-Data-Block",
          name: "Não Comissionados",
          state: `/smcs/nao-comissionados`,
          type: "link",
        },
        {
          icon: "i-Close-Window",
          name: "Não Registrados",
          state: "/smcs/nao-registrados",
          type: "link",
        },
        {
          icon: "i-Bell",
          name: "Alarmes",
          state: "/smcs/alarmes",
          type: "link",
        },
      ],
    },
    {
      name: "Agrupamentos",
      type: "dropDown",
      icon: "i-Folders",
      sub: [
        {
          icon: "i-Tag-2",
          name: "Listar Tags",
          state: "/groups/listar/tags",
          type: "link",
        },
        {
          icon: "i-Tag-3",
          name: "Pesquisar por TAGs",
          state: "/groups/tags",
          type: "link",
        },
        {
          icon: "i-Bookmark",
          name: "Grupos Cadastrados",
          state: `/groups/registrados`,
          type: "link",
        },
      ],
    },
    {
      name: 'Notas de Serviços',
      type: "link",
      state: "/notas-de-servico/lista",
      icon: "i-Receipt",
    },
    {
      name: "Aplicativo",
      type: "link",
      state: "/aplicativo",
      icon: "i-Android",
    },
  ];



  adminMenu: IMenuItem[];
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  constructor(private authService: AuthService,
    private _translateService: TranslateService,
  ) {
    this.getMenu();
    this._translateService.onLangChange.subscribe(async () => {
      this.getMenu();
    })
  }
  async ngOnInit() {
  }

  async getMenu() {
    this._translateService.get('NavigationMenu').subscribe(response => {
      this.translateMenu = response;
      this._buildMenu();
    })
  }
  // You can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(userType: UserType) {
    if (userType === UserType.ADMIN) {
      this.menuItems.next(this.adminMenu);
    }
    else
      this.menuItems.next(this.defaultMenu);
  }



  private buildAdminMenu() {
    this.adminMenu = [
      {
        name: this.translateMenu.DashBoard.Title,
        type: "dropDown",
        icon: 'i-Statistic',
        sub: [
          {
            name: this.translateMenu.DashBoard.Readings,
            type: "link",
            icon: 'i-Monitor-5',
            state: '/dashboard/leituras',
          },
          {
            name: this.translateMenu.DashBoard.Alarms,
            type: "link",
            icon: 'i-Bell',
            state: '/dashboard/alarmes',
          }
        ]
      },
      {
        name: this.translateMenu.Meters.title,
        type: "dropDown",
        icon: "i-Dashboard",
        sub: [
          {
            icon: "i-Data-Save",
            name: this.translateMenu.Meters.allMeters,
            state: "/medidores/todos",
            type: "link",
          },
          {
            icon: "i-Data-Save",
            name: this.translateMenu.Meters.comissioned,
            state: "/medidores/comissionados",
            type: "link",
          },
          {
            icon: "i-Data-Block",
            name: this.translateMenu.Meters.notComissioned,
            state: "/medidores/nao-comissionados",
            type: "link",
          },
          {
            icon: "i-Close-Window",
            name: this.translateMenu.Meters.notRegistred,
            state: "/medidores/nao-registrados",
            type: "link",
          },
          {
            icon: "i-Bell",
            name: this.translateMenu.Meters.alarms,
            state: "/medidores/alarmes",
            type: "link",
          },
        ],
      },
      {
        name: this.translateMenu.Tickets.Title,
        type: 'link',
        icon: 'i-Ticket',
        state: '/relatorio'
      },
      {
        name: this.translateMenu.Audit.Title,
        type: "dropDown",
        icon: 'i-Receipt',
        sub: [
          {
            name: this.translateMenu.Audit.Installation,
            type: "link",
            icon: 'i-Monitor-5',
            state: '/auditoria/instalacao',
          },
        ]
      },

      {
        name: "SMC's",
        type: "dropDown",
        icon: "i-Posterous",
        sub: [
          {
            icon: "i-Data-Save",
            name: this.translateMenu.Meters.comissioned,
            state: `/smcs/comissionados`,
            type: "link",
          },
          {
            icon: "i-Data-Block",
            name: this.translateMenu.Meters.notComissioned,
            state: `/smcs/nao-comissionados`,
            type: "link",
          },
          {
            icon: "i-Close-Window",
            name: this.translateMenu.Meters.notRegistred,
            state: "/smcs/nao-registrados",
            type: "link",
          },
          {
            icon: "i-Bell",
            name: "Alarmes",
            state: "/smcs/alarmes",
            type: "link",
          },
        ],
      },
      {
        name: this.translateMenu.Balance.title,
        type: 'dropDown',
        icon: 'i-Bar-Chart-2',
        sub: [
          {
            name: this.translateMenu.Balance.transformer,
            state: '/balanco/por-transformador',
            type: "link",
            icon: 'i-One-Window'

          },
          {
            name: this.translateMenu.Balance.area,
            state: '/balanco/por-area',
            type: "link",
            icon: 'i-Two-Windows'
          },
          {
            name: this.translateMenu.Balance.region,
            state: '/balanco/por-regiao',
            type: "link",
            icon: 'i-Two-Windows'
          }
        ]
      },
      {
        name: this.translateMenu.clusterCommand.title,
        type: "dropDown",
        icon: 'i-Conference',
        sub: [
          {
            icon: 'i-Money-2',
            name: this.translateMenu.clusterCommand.readings,
            type: 'link',
            state: '/comando-em-massa/leituras'

          },
          {
            icon: 'i-Data-Power',
            name: this.translateMenu.clusterCommand.relay,
            type: 'link',
            state: '/comando-em-massa/corte-ou-religamento'

          }
        ]
      },
      {
        name: this.translateMenu.schedulesCommand.title,
        type: "dropDown",
        icon: 'i-Calendar-3',
        sub: [
          {
            icon: 'i-Repeat-3',
            name: this.translateMenu.schedulesCommand.systemCommands,
            type: 'link',
            state: '/comando-agendados/comandos-de-sistema'
          },
          {
            icon: 'i-Clock-Forward',
            name: this.translateMenu.schedulesCommand.list,
            type: 'link',
            state: '/comando-agendados/listagem-de-rotina'
          },
          {
            icon: 'i-Calendar-3',
            name: this.translateMenu.schedulesCommand.newCommand,
            type: 'link',
            state: '/comando-agendados/adicionar-rotina'
          }
        ]
      },
      {
        name: this.translateMenu.group.title,
        type: "dropDown",
        icon: "i-Folders",
        sub: [
          {
            icon: "i-Tag-2",
            name: this.translateMenu.group.listTags,
            state: "/grupos/listar/tags",
            type: "link",
          },
          {
            icon: "i-Tag-3",
            name: this.translateMenu.group.searchTags,
            state: "/grupos/tags",
            type: "link",
          },
          {
            icon: "i-Bookmark",
            name: this.translateMenu.group.registered,
            state: `/grupos/registrados`,
            type: "link",
          },
        ],
      },
      {
        name: this.translateMenu.Crud.title,
        type: "dropDown",
        icon: "i-Add-Window",
        sub: [
          {
            name: this.translateMenu.Crud.meters.title,
            type: "dropDown",
            icon: "i-Dashboard",
            sub: [
              {
                name: this.translateMenu.Crud.meters.all,
                state: "/cadastros-medidores/todos",
                type: "link",
              },
              {
                name: this.translateMenu.Crud.meters.fileUpload,
                state: "/cadastros-medidores/medidores-upload",
                type: "link",
              },
              {
                name: this.translateMenu.Crud.meters.models,
                state: "/cadastros-medidores/modelos",
                type: "link",
              },
              {
                name: this.translateMenu.Crud.meters.registers,
                state: "/cadastros-medidores/registrados",
                type: "link",
              },
            ],
          },
          {
            name: "SMC\'s",
            type: "dropDown",
            icon: "i-Posterous",
            sub: [
              {
                name: this.translateMenu.Crud.smc.list,
                state: `/cadastro-smcs/todos`,
                type: "link",
              },
              {
                name: this.translateMenu.Crud.smc.File,
                state: `/cadastro-smcs/arquivo`,
                type: "link",
              },
              {
                name: this.translateMenu.Crud.smc.models,
                state: "/cadastro-smcs/modelos",
                type: "link",
              },
            ],
          },
          {
            name: this.translateMenu.Crud.transformers.title,
            type: 'dropDown',
            icon: 'i-Keyboard3',
            sub: [
              {
                name: this.translateMenu.Crud.transformers.title,
                state: '/cadastro-transformadores/transformadores',
                type: 'link'
              },
              {
                name: this.translateMenu.Crud.transformers.areas,
                state: '/cadastro-transformadores/areas',
                type: 'link',
              },
              {
                name: this.translateMenu.Crud.transformers.regions,
                state: "/cadastro-transformadores/regiao",
                type: 'link'
              }
            ]
          },
          {
            name: this.translateMenu.Crud.keyRegisters,
            type: "link",
            icon: "i-Key",
            state: "/chaves/listar",
          },
          {
            name: this.translateMenu.Crud.rateTypes,
            type: "link",
            icon: "i-Key",
            state: "/tipos-de-tarifas/listar",
          },
          {
            name: this.translateMenu.Crud.firmware,
            type: "link",
            icon: "i-Windows-2",
            state: "/firmware/listar",
          },
        ],
      },
      {
        name: this.translateMenu.ServicesNotes,
        type: "link",
        state: "/notas-de-servico/lista",
        icon: "i-Receipt",
      },
      {
        name: this.translateMenu.App,
        type: "link",
        state: "/aplicativo",
        icon: "i-Android",
      },
      {
        name: this.translateMenu.UserControl,
        type: "link",
        state: "/usuarios/",
        icon: "i-Lock-User",
      },
    ];
    this.menuItems.next(this.adminMenu);
  }

  private buildDefaultMenu() {
    this.defaultMenu = [
      {
        name: this.translateMenu.DashBoard.Title,
        type: "dropDown",
        icon: 'i-Statistic',
        sub: [
          {
            name: this.translateMenu.DashBoard.Readings,
            type: "link",
            icon: 'i-Monitor-5',
            state: '/dashboard/leituras',
          },
          {
            name: this.translateMenu.DashBoard.Alarms,
            type: "link",
            icon: 'i-Bell',
            state: '/dashboard/alarmes',
          }
        ]
      },
      {
        name: this.translateMenu.Meters.title,
        type: "dropDown",
        icon: "i-Dashboard",
        sub: [
          {
            icon: "i-Data-Save",
            name: this.translateMenu.Meters.allMeters,
            state: "/medidores/todos",
            type: "link",
          },
          {
            icon: "i-Data-Save",
            name: this.translateMenu.Meters.comissioned,
            state: "/medidores/comissionados",
            type: "link",
          },
          {
            icon: "i-Data-Block",
            name: this.translateMenu.Meters.notComissioned,
            state: "/medidores/nao-comissionados",
            type: "link",
          },
          {
            icon: "i-Close-Window",
            name: this.translateMenu.Meters.notRegistred,
            state: "/medidores/nao-registrados",
            type: "link",
          },
          {
            icon: "i-Bell",
            name: this.translateMenu.Meters.alarms,
            state: "/medidores/alarmes",
            type: "link",
          },
        ],
      },
      {
        name: "SMC's",
        type: "dropDown",
        icon: "i-Posterous",
        sub: [
          {
            icon: "i-Data-Save",
            name: this.translateMenu.Meters.comissioned,
            state: `/smcs/comissionados`,
            type: "link",
          },
          {
            icon: "i-Data-Block",
            name: "Não Comissionados",
            state: `/smcs/nao-comissionados`,
            type: "link",
          },
          {
            icon: "i-Close-Window",
            name: this.translateMenu.Meters.notRegistred,
            state: "/smcs/nao-registrados",
            type: "link",
          },
          {
            icon: "i-Bell",
            name: "Alarmes",
            state: "/smcs/alarmes",
            type: "link",
          },
        ],
      },
      {
        name: this.translateMenu.group.title,
        type: "dropDown",
        icon: "i-Folders",
        sub: [
          {
            icon: "i-Tag-2",
            name: this.translateMenu.group.listTags,
            state: "/grupos/listar/tags",
            type: "link",
          },
          {
            icon: "i-Tag-3",
            name: this.translateMenu.group.searchTags,
            state: "/grupos/tags",
            type: "link",
          },
          {
            icon: "i-Bookmark",
            name: this.translateMenu.group.registered,
            state: `/grupos/registrados`,
            type: "link",
          },
        ],
      },
      {
        name: this.translateMenu.ServicesNotes,
        type: "link",
        state: "/notas-de-servico/lista",
        icon: "i-Receipt",
      },
      {
        name: this.translateMenu.App,
        type: "link",
        state: "/aplicativo",
        icon: "i-Android",
      },
    ];
  }


  private _buildMenu() {
    this.buildAdminMenu();
    this.buildDefaultMenu();
  }
}
