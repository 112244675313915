import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IogHttpService } from 'src/app/security/iog-http.service';
import { AbsolutePeriod, VoltageValues } from 'src/app/core/models/SmcDTO';

@Injectable({ providedIn: 'root' })
export class CommandsService {
  static url = environment.apiUrlBack + '/commands/';
  constructor(private http: IogHttpService) { }

  relayOn(serial) {
    return this.http.put(`${CommandsService.url}relay-on/${serial}`, '');
  }

  relayOff(serial) {
    return this.http.put(`${CommandsService.url}relay-off/${serial}`, '');
  }

  getRelayStatus(serial) {
    return this.http.get(`${CommandsService.url}get-relay/${serial}`);
  }

  getSmcMetersList(smcSerial: string) {
    return this.http.get(CommandsService.url + `get-serial-meters-smc/${smcSerial}`);
  }

  disableCollectiveCut(smcSerial: string, hours: number) {
    return this.http.put(
      `${CommandsService.url}disable-collective-cut/${smcSerial}/${hours}`,
      ''
    );
  }

  enabaleColletiveCut(smcSerial: string) {
    return this.http.put(
      `${CommandsService.url}enable-collective-cut/${smcSerial}`,
      ''
    );
  }

  getColletiveCutStatus(smcSerial: string) {
    return this.http.get(CommandsService.url + `get-door-status/${smcSerial}`, '');
  }

  getAbsoluteDoorStatus(smcSerial: string) {
    return this.http.get(CommandsService.url + `get-door-status/absolute/${smcSerial}`, '');
  }

  rollBack(smcSerial: string) {
    return this.http.put(
      `${CommandsService.url}relay-rollback/${smcSerial}`,
      ''
    );
  }

  getInstantMeasure(meterSerial: string) {
    return this.http.get(
      `${CommandsService.url}instant-measure/${meterSerial}`
    );
  }

  resetNic(serial: string, seconds: number = 10) {
    return this.http.put(
      `${CommandsService.url}nic-reset/${serial}?seconds=${seconds}`,
      ''
    );
  }

  getBilling(serial: string) {
    return this.http
      .get(environment.apiUrlBack + `/billing/${serial}`)
      .map((response) => response.valueOf());
  }

  updateDateTime(serial: string) {
    return this.http.put(`${CommandsService.url}update-datetime/${serial}`, '');
  }

  changeSmcTimeOut(serial: string, timeInMinutes: number, startDateTime) {
    return this.http.post(
      CommandsService.url +
      `set-smc-uplink-interval/${serial}?startTime=${startDateTime}&periodInMinutes=${timeInMinutes}`,
      {}
    );
  }

  changeSmiTimeOut(serial: string, timeInMinutes: number, startDateTime) {
    return this.http.post(
      CommandsService.url +
      `set-smi-uplink-interval/${serial}?startTime=${startDateTime}&periodInMinutes=${timeInMinutes}`,
      {}
    );
  }

  getAllRelayStatus(smcSerial: string) {
    return this.http.get(
      `${CommandsService.url}get-relay-all-meters-smc/${smcSerial}`
    );
  }

  relayOnAllMetersSmc(serialSmc: string) {
    return this.http.put(`${CommandsService.url}relay-on-all-meters-smc/${serialSmc}`, {});
  }

  recoverEnergy(smcSerial: string, quantity: number) {
    return this.http.post(`${CommandsService.url}recover-energy-smc/${smcSerial}?quantity=${quantity}`, {});
  }

  getInstantMeasureSmc(serial: string) {
    return this.http.get(`${CommandsService.url}instant-measure-smc/${serial}`);
  }

  recoverVoltageOutShort(meterSerial: string) {
    return this.http.get(`${CommandsService.url}voltage-out-short/${meterSerial}`);
  }


  recoverVoltageOutLong(meterSerial: string) {
    return this.http.get(`${CommandsService.url}voltage-out-long/${meterSerial}`);
  }

  setStandartFrozenData({
    serial
  }) {
      return this.http.post(CommandsService.url + `set-standard-frozen-data/${serial}`, {});
  }


  setSmiQueeInterval({
    serial,
    startTime,
    periodInMinutes
  }) {
      return this.http.post(CommandsService.url + `set-smi-qee-interval/${serial}?startTime${startTime}&periodInMinutes=${periodInMinutes}`, {});
  }

  setSmiLongOutVoltageInterval({
    serial,
    startTime,
    periodInMinutes
  }) {
      return this.http.post(CommandsService.url + `set-smi-long-out-voltage-interval/${serial}?startTime=${startTime}&periodInMinutes=${periodInMinutes}`, {});
  }

  requestVoltageOutShortByQuantity(serial: string, value: number) {
    return this.http.post(`${CommandsService.url}voltage-out-short-by-obj/${serial}?entries=` + value, {});
  }

  requestVoltageOutLongByQuantity(serial: string, value: number) {
    return this.http.post(`${CommandsService.url}voltage-out-long-by-obj/${serial}?entries=` + value, {});
  }


  changeNetwork(serial: string, value: number) {
    return this.http.put(`${CommandsService.url}change-network-type/${serial}?type=` + value, {});
  }

  changeTech(serial: string, value: number) {
    return this.http.put(`${CommandsService.url}change-network-tech/${serial}?type=` + value, {});
  }

  getTech(serial: string) {
    return this.http.get(`${CommandsService.url}network-tech/${serial}`);
  }

  getNetwork(serial: string) {
    return this.http.get(`${CommandsService.url}network-type/${serial}`);
  }

  changeAbsolutePeriod(period: AbsolutePeriod, serial: string) {
    return this.http.post(`${CommandsService.url}set-smc-uplink-interval/${serial}/absolute?period=` + period, {});
  }

  setVoltage(value: VoltageValues, serial: string) {
    return this.http.put(`${CommandsService.url}set-voltage/${serial}?value=` + value, {});
  }

  getVoltage(serial: string) {
    return this.http.get(`${CommandsService.url}get-voltage/${serial}`);
  }

  setDisplayConfiguration(serial: string) {
    return this.http.put(`${CommandsService.url}set-display-configuration/${serial}`, {});
  }

  setDisplayConfigurationManual(serial: string) {
    return this.http.put(`${CommandsService.url}set-display-configuration-manual/${serial}`, {});
  }

  recoverActiveEnergy(serial: string) {
    return this.http.put(`${CommandsService.url}get-energy-totalizer/${serial}`, {});
  }

  recoverEventQeeIndicator(serial: string) {
    return this.http.get(`${CommandsService.url}recover-event-qee-indicator/${serial}`, {});
  }

  setEventQeeIndicator(serial: string) {
    return this.http.get(`${CommandsService.url}set-quality-measure-params/${serial}`, {});
  }
}
