import { FileService } from 'src/app/shared/services/file.service';
import { MetersService } from 'src/app/shared/services/meters.service';
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown/multiselect.model";
import * as XLS from "xlsx";
import {
  AccountStatus,
  MeterComissionedDTO,
  MeterDTO,
  MeterPhase,
} from "src/app/core/models/MeterDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { TagsDTO } from "src/app/core/models/TagsTDO";
import { Utils } from "src/app/shared/utils";
import { HandleErrorService } from "../../services/handle-error.service";
import { TagsServices } from "../../services/tags.service";
import { TranslateInCodeService } from '../../services/translate-in-code.service';
import { EnumMapperDTO } from 'src/app/core/models/EnumMapperDTO';
import { TranslateService } from '@ngx-translate/core';
import { ModemDTO, SignalQuality } from 'src/app/core/models/ModemDTO';

@Component({
  selector: "app-meter-table-comissioned",
  templateUrl: "./meter-table-comissioned.component.html",
  styleUrls: ["./meter-table-comissioned.component.scss"],
})
export class MeterTableComissionedComponent {
  @Input("data") items: MeterComissionedDTO[] = [];
  @Input() meter: MeterComissionedDTO;
  @Input() page: PaginationResponseDTO;
  @Input() showView = true;
  @Input() isLoading = true;
  @Input() isReloading = false;

  @Output() navigateToMeter = new EventEmitter();
  @Output() filterMeter = new EventEmitter();
  @Output() reload = new EventEmitter();

  public MeterPhase = MeterPhase;
  public MeterDTO = MeterDTO;
  public Utils = Utils;
  public MeterComissioned = MeterComissionedDTO;
  public Modem = ModemDTO;

  public startDate = "";
  public endDate = "";
  public searchString = "";
  public sortOrder = "updatedAt-desc";
  public currentPage = 0;
  public pageSize = 15;
  public placeHolderLabel =
    "Pesquisar pelo serial, código de instalação , EUI do modem ou Fabricante";
  public filledColor: string = '#fff';

  public tagsDropDownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "visibleId",
    textField: "keyword",
    enableCheckAll: false,
    selectAllText: "Selecionar todas as tags",
    unSelectAllText: "Remover todas as tags.",
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: "Nenhuma tag encontrado",
    searchPlaceholderText: "Procurar tag pelo nome",
  };

  private filter = {
    pageSize: this.pageSize,
    sortOrder: this.sortOrder,
    searchString: this.searchString,
    currentPage: this.currentPage,
    startDate: this.startDate,
    endDate: this.endDate,
    signal: '',
  };

  selectedTag: TagsDTO = null;
  selectedTags: TagsDTO[] = [];
  tags: TagsDTO[] = [];
  allTags: TagsDTO[] = [];
  meterTags: TagsDTO[] = [];
  public filterQuery = "";
  isLoadingAllMetersComissioned = false;

  EnumMapperDTO: EnumMapperDTO;
  SiganlStrengthMapper: EnumMapperDTO;
  constructor(
    private modalService: NgbModal,
    private tagService: TagsServices,
    private handleError: HandleErrorService,
    private meterService: MetersService,
    private fileService: FileService,
    private translateInCode: TranslateInCodeService,
    private _translateServices: TranslateService,
  ) { }

  async ngOnInit() {
    await this.getRelayStatusTranslate();
    this._translateServices.onLangChange.subscribe(async () => {
      await this.getRelayStatusTranslate();
    });
    this.getTags();
  }

  async getRelayStatusTranslate() {
    this.EnumMapperDTO = await this.translateInCode
      .getTranslate('Meter.AccountStatus', AccountStatus);
    this.SiganlStrengthMapper = await this.translateInCode.getTranslate('ModemInfo.SignalQuality', SignalQuality);
  }

  private getWorkSheet(rows): XLS.WorkSheet {
    const workSheet = XLS.utils.json_to_sheet([...rows]);
    workSheet["!cols"] = [{ width: 45 }];
    return workSheet;
  }

  private getWorkBook(workSheet: XLS.WorkSheet): XLS.WorkBook {
    return {
      Sheets: {
        "Medidores": workSheet,
      },
      SheetNames: ["Medidores"]
    }
  }

  private writeXls(workBook) {
    return XLS.write(workBook, { bookType: 'xlsx', type: 'array' })
  }

  downloadAllMetersComissioned() {
    this.isLoadingAllMetersComissioned = true;
    this
      .meterService
      .getMetersComissioned()
      .toPromise()
      .then((metersComissioned: MeterComissionedDTO[]) => this.processMeters(metersComissioned))
      .then(this.getWorkSheet)
      .then(this.getWorkBook)
      .then(this.writeXls)
      .then(buffer => this.fileService.saveFileAsExcel(buffer, 'Todos os Medidores Comissionados'))
      .then(() => this.isLoadingAllMetersComissioned = false)
      .catch((error) => {
        this.handleError.handle(error);
        this.isLoadingAllMetersComissioned = false;
      });
  }

  private processMeters(meters: MeterDTO[]) {
    let metersList = [];

    meters.forEach(_meter => {
      let tags: string = '';
      let registers: string = '';
      _meter.tags.forEach(tag => {
        tags += tag.keyword + ';';
      })
      if (tags.slice(tags.length - 1) === ';') {
        tags = tags.slice(0, tags.length - 1);
      }
      _meter.meterRegisters.forEach(_register => {
        registers += _register.code + ';';
      })
      if (registers.slice(registers.length - 1) === ';')
        registers = registers.slice(0, registers.length - 1);

      const meter = {
        Fase: MeterDTO.getPhaseValue(_meter),
        Modelo: _meter.meterModel.name,
        Smc: _meter.smc !== null ? _meter.smc.serial : null,
        Serial: _meter.serial,
        Latitude: _meter.latitude,
        Longitude: _meter.longitude,
        Tags: tags,
        ['Estado do Relé']: MeterDTO.getRelayStatusValue(_meter.accountantStatus),
        ['Comissionamento']: _meter.comissioned ? 'Comissionado' : 'Não Comissionado',
        ['Código de Instalação']: _meter.installation,
        ['Estado de Comunicação']: _meter.online ? 'Online' : 'Offline',
        DeviceEui: _meter.modem !== null ? _meter.modem.deviceEui : null,
        Fabricante: _meter.meterModel !== null ? _meter.meterModel.manufacturer.name : null

      };
      metersList.push(meter);
    });

    return metersList;
  }

  getTags() {
    this.tagService
      .getTags()
      .toPromise()
      .then((data: TagsDTO[]) => {
        this.allTags = data;
        this.tags = this.allTags;
      })
      .catch((error) => {
        this.handleError.handle(error);
      });
  }

  navigate(serial) {
    this.navigateToMeter.emit(serial);
  }

  updateSearchString(searchValue) {
    this.currentPage = 0;
    this.searchString = searchValue;
    this.filter["searchString"] = this.searchString;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  sortByProp(prop) {
    const sort = prop.sorts[0];
    this.currentPage = 0;
    this.sortOrder = `${sort.prop}-${sort.dir}`;
    this.filter["currentPage"] = this.currentPage;
    this.filter["sortOrder"] = this.sortOrder;
    this.emiteValue(this.filter);
  }

  changePage(value) {
    const { page } = value;
    this.currentPage = page ? page - 1 : 0;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  changeSizeValue(value) {
    this.pageSize = value;
    this.currentPage = 0;
    this.filter["pageSize"] = this.pageSize;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  filterByDate() {
    this.filter["startDate"] = this.startDate;
    this.filter["endDate"] = this.endDate;
    this.currentPage = 0;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
    this.closeModal();
  }

  finishOperation() {
    this.reseteDatas();
    this.closeModal();
  }

  openModal(content, meter?: MeterComissionedDTO, event?, firstChild?) {
    event && event.stopPropagation();
    firstChild &&
      firstChild.parentElement &&
      firstChild.parentElement.parentElement &&
      firstChild.parentElement.parentElement.blur();
    this.meter = meter;
    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  addRemoveTag(tag: TagsDTO) {
    this.selectedTag = this.tags.find(
      (meterTag) => meterTag.visibleId === tag.visibleId
    );

    this.meterTags = this.meterTags.map((meterTag) => {
      return this.tags.find(
        (tag) => tag.visibleId === meterTag.visibleId && !!meterTag
      );
    });

  }

  private reseteDatas() {
    this.startDate = "";
    this.endDate = "";
  }

  private closeModal() {
    this.modalService.dismissAll();
  }

  private emiteValue(value) {
    this.filterMeter.emit(value);
  }

  removeFilter() {
    this.reseteValues();
    this.emiteValue(this.filter);
    this.closeModal();
  }

  private reseteValues() {
    this.sortOrder = "updatedAt-desc";
    this.searchString = "";
    this.startDate = "";
    this.endDate = "";
    this.currentPage = 0;
    this.filter.signal = '';
    this.filledColor = '#fff';
    this.filter["currentPage"] = this.currentPage;
    this.filter["endDate"] = this.endDate;
    this.filter["startDate"] = this.startDate;
    this.filter["sortOrder"] = this.sortOrder;
    this.filter["searchString"] = this.searchString;
  }

  reloadMeters() {
    this.reload.emit();
  }
  changeSignal(signal) {
    this.filledColor = signal === null ? '#fff' : this.Modem.getSinalColor(signal);
    this.filter.signal = signal === null ? '' : signal;
    this.emiteValue(this.filter);
  }
}
