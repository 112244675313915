import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import { NgbModal, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { FormControl } from "@angular/forms";
import { Utils } from "src/app/shared/utils";
import * as moment from "moment";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";
import { MeterModelDTO } from "src/app/core/models/MeterModelDTO";

const validUplinksPeriods: number[] = [
  10, 20, 30, 60, 120, 240, 360, 480, 720, 1440,
];
const validEletraUplinkPeriods: number[] = [
  15, 30, 60, 120, 240, 360, 480, 720, 1440,
];
@Component({
  selector: "app-change-period",
  templateUrl: "./change-period.component.html",
  styleUrls: ["./change-period.component.scss"],
})
export class ChangePeriodComponent implements AfterViewInit {
  @Input() serialMedidor: string;
  @Input() model: MeterModelDTO;
  @Input() isChanging = false;
  @Input() isLoading = true;
  @Output() changePeriod = new EventEmitter();
  @Input() currentTimeInMinutes: number = null;
  Utils = Utils;

  modalRef: any = null;
  isTwentyFoursHours = false;

  public canChangePeriod = [UserType.ADMIN];

  seconds = false;
  isValid = false;
  minuteStep = 30;
  hourStep = 1;
  periodTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  periodMinutesSteps = 10;
  periodHoursSteps = 1;
  time: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  minDate = {
    year: new Date(moment.now()).getFullYear(),
    month: new Date(moment.now()).getMonth() + 1,
    day: new Date(moment.now()).getDate(),
  };
  maxDate = {
    year: new Date(moment.now()).getFullYear() + 1,
    month: new Date(moment.now()).getMonth() + 1,
    day: new Date(moment.now()).getDate(),
  };

  TimeForm = new FormControl("", (control: FormControl) => {
    const value = control.value;
    if (!value) {
      return { valid: true };
    }

    if (value.minute < 10 && value.hour === 0) {
      return { min: true };
    }
    return null;
  });

  constructor(
    private modalService: NgbModal,
    public authService: AuthService
  ) { }
  ngAfterViewInit(): void {
    this.periodMinutesSteps =
      this.model.manufacturer.name === "ELETRA" || this.model.name === "ABSOLUTE" ? 15 : 10;
  }

  openModal(modal: any) {
    this.modalRef = this.modalService.open(modal, { centered: true });
  }

  closeModal() {
    this.modalRef.close();
  }
  closeAll() {
    this.modalService.dismissAll();
  }

  changePeriodo() {
    this.currentTimeInMinutes = this.isTwentyFoursHours
      ? 24 * 60
      : this.periodTime.hour * 60 + this.periodTime.minute;
    this.changePeriod.emit({
      time: this.currentTimeInMinutes,
      timeToStart: this.time,
    });
    this.closeModal();
  }

  modelChange() {
    if (this.periodTime.hour === 0) {
      this.periodHoursSteps = 1;
      this.periodMinutesSteps =
        this.model.manufacturer.name === "ELETRA" ||
          this.model.name === "ABSOLUTE"
          ? 15
          : 10;
    }
    if (
      this.periodTime.hour > 0 &&
      this.periodTime.hour < 2 &&
      this.periodTime.minute >= 30
    ) {
      this.periodMinutesSteps = 30;
    }
    if (this.periodTime.hour == 1) {
      this.periodMinutesSteps = 0;
    }
    if (this.periodTime.hour >= 2 && this.periodTime.hour < 8) {
      this.periodHoursSteps = 2;
      this.periodMinutesSteps = 0;
    }
    if (this.periodTime.hour >= 8 && this.periodTime.hour < 12) {
      this.periodHoursSteps = 4;
    }
    if (this.periodTime.hour === 12) {
      this.periodHoursSteps = 11;
    }
    if (this.periodTime.hour > 12) {
      this.periodHoursSteps = 1;
      this.periodMinutesSteps = 0;
    }
    if (this.periodTime.hour === 23) {
      this.periodTime.minute = 0;
    }
    this.isValid =
      this.model.manufacturer.name === "ELETRA" ||
        this.model.name === "ABSOLUTE"
        ? validEletraUplinkPeriods.includes(
          this.isTwentyFoursHours
            ? 1440
            : this.periodTime.hour * 60 + this.periodTime.minute
        )
        : validUplinksPeriods.includes(
          this.isTwentyFoursHours
            ? 1440
            : this.periodTime.hour * 60 + this.periodTime.minute
        );
  }
}
