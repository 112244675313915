export enum Companys {
  Cemar = 0,
  Celpa = 1,
  Energy_IP = 2,
  SAP_ALAGOAS = 3,
  PIAUI = 4
}

export enum ActionType {
  TurnOffPower = 1,
  TurnOnPower = 2,
  RequestRead = 3,
  RequestNoteLq = 4,
}

export enum SchedulingStatusType {
  Waiting = 1,
  Processing = 2,
  Running = 3,
  CommunicationWithMDCFailed = 4,
  InternalFailure = 5,
  MDCTicketFinalized = 6,
  MDCTicketFailed = 7,
  FinishedWithSuccess = 8,
  FinishedWithFailure = 9,
  Cancelled = 10,
}

export enum CommandType {
  RelayOff = 0,
  RelayOn = 1,
  RelayStatus = 2,
  EnableCollectiveCut = 3,
  DisableCollectiveCut = 4,
  Rollback = 5,
  SendKeyAk = 6,
  SendKeyEk = 7,
  ListMetersSmcSerial = 8,
  UpdateDateTime = 9,
  InstantMeasure = 10,
  NicReset = 11,
}

export interface IDataRequest {
  NumeroNota: string;
}

export class DataRequestDTO implements IDataRequest {
  public NumeroNota: string;
}

export interface IReceipt {
  actionType: ActionType;
  company: Companys;
  createdAt: string;
  dataRequest: DataRequestDTO;
  dataRequestType: any;
  dataResponse: any;
  dataResponseType: any;
  deadline: string;
  id: string;
  meterSerial: string;
  startDate: string;
  status: SchedulingStatusType;
  trackingCode: number;
  updatedAt: string;
}

export enum TicketsStatus {
  Waiting = 0,
  Executing = 1,
  Finished = 2,
  PartiallyFinished = 3,
  Interupted = 4,
  MiddlewareNotResponding = 5,
  FailedToStart = 6,
  FailedToContinue = 7,
  Failed = 8,
  MiddleWareOffline = 9,
  CommandTimeOutReached = 10,
  Cancelled = 11,
  WaitingScheduleDateTime = 12
}

export class ReceiptDTO implements IReceipt {
  public actionType: ActionType;
  public company: Companys;
  public createdAt: string;
  public dataRequest: any;
  public dataRequestType: any;
  public dataResponse: any;
  public dataResponseType: any;
  public deadline: string;
  public id: string;
  public status: SchedulingStatusType;
  public trackingCode: number;
  public meterSerial: string;
  public startDate: string;
  public updatedAt: string;

  constructor() {
    this.dataRequest = JSON.parse(this.dataRequest);
  }

  public static getActionType(action: ActionType): string {
    switch (action) {
      case ActionType.TurnOffPower:
        return "Desativar Relé";
      case ActionType.TurnOnPower:
        return "Ativar Relé";
      case ActionType.RequestRead:
        return "Requisições de Leitura";
      case ActionType.RequestNoteLq:
        return "Requisitar Parâmetros de qualidade";
    }
  }

  public static getCompany(company: Companys): string {
    switch (company) {
      case Companys.Celpa:
        return "CELPA";
      case Companys.Cemar:
        return "CEMAR";
      case Companys.Energy_IP:
        return "ENERGY IP";
      case Companys.SAP_ALAGOAS:
        return 'ALAGOAS';
      case Companys.PIAUI:
        return 'PIAUI';
    }
  }

  public static getStatus(status: SchedulingStatusType): string {
    switch (status) {
      case SchedulingStatusType.Waiting:
        return "Aguardando";
      case SchedulingStatusType.Processing:
        return "Processando";
      case SchedulingStatusType.Running:
        return "Executando";
      case SchedulingStatusType.CommunicationWithMDCFailed:
        return "Falha comunicando com MDC";
      case SchedulingStatusType.InternalFailure:
        return "Falha na Integração";
      case SchedulingStatusType.MDCTicketFinalized:
        return "Ticket Finalizado no MDC";
      case SchedulingStatusType.MDCTicketFailed:
        return "Ticket Falhou";
      case SchedulingStatusType.FinishedWithSuccess:
        return "Finalizado com sucesso";
      case SchedulingStatusType.FinishedWithFailure:
        return "Finalizado com erro";
      case SchedulingStatusType.Cancelled:
        return "Cancelado";
    }
  }

  public static getStatusClass(status: SchedulingStatusType) {
    switch (status) {
      case SchedulingStatusType.Waiting:
        return "badge badge-info mr-1";
      case SchedulingStatusType.Processing:
        return "badge badge-waring mr-1";
      case SchedulingStatusType.Running:
        return "badge badge-warning mr-1";
      case SchedulingStatusType.CommunicationWithMDCFailed:
        return "badge badge-danger mr-1";
      case SchedulingStatusType.InternalFailure:
        return "badge badge-danger mr-1";
      case SchedulingStatusType.MDCTicketFinalized:
        return "badge badge-success mr-1";
      case SchedulingStatusType.MDCTicketFailed:
        return "badge badge-danger mr-1";
      case SchedulingStatusType.FinishedWithSuccess:
        return "badge badge-success mr-1";
      case SchedulingStatusType.FinishedWithFailure:
        return "badge badge-danger mr-1";
      case SchedulingStatusType.Cancelled:
        return "badge badge-danger mr-1";
    }
  }

  public static getTicketStatus(ticketStatus: TicketsStatus) {
    switch (ticketStatus) {
      case TicketsStatus.Waiting:
        return "Aguardando";
      case TicketsStatus.Executing:
        return "Executando";
      case TicketsStatus.Finished:
        return "Finalizado";
      case TicketsStatus.PartiallyFinished:
        return "Finalizado Parcialmente";
      case TicketsStatus.Interupted:
        return "Interrompido";
      case TicketsStatus.MiddlewareNotResponding:
        return "Sem resposta do MiddleWare";
      case TicketsStatus.FailedToStart:
        return "Falha Inicializando";
      case TicketsStatus.FailedToContinue:
        return "Erro durante o processamento";
      case TicketsStatus.Failed:
        return "Falhou";
      case TicketsStatus.MiddleWareOffline:
        return "MiddleWare Offline ou Indisponível";
      case TicketsStatus.CommandTimeOutReached:
        return "Excedeu Tempo de Execução";
      case TicketsStatus.Cancelled:
        return "Cancelado";
    }
  }

  static getTicketStatusClass(ticketStatus: TicketsStatus) {
    switch (ticketStatus) {
      case TicketsStatus.Waiting:
        return "badge badge-warning mr-1";
      case TicketsStatus.Executing:
        return "badge badge-warning mr-1";
      case TicketsStatus.Finished:
        return "badge badge-success mr-1";
      case TicketsStatus.PartiallyFinished:
        return "badge badge-warning mr-1";
      case TicketsStatus.Interupted:
        return "badge badge-danger mr-1";
      case TicketsStatus.MiddlewareNotResponding:
        return "badge badge-danger mr-1";
      case TicketsStatus.FailedToStart:
        return "badge badge-danger mr-1";
      case TicketsStatus.FailedToContinue:
        return "badge badge-danger mr-1";
      case TicketsStatus.Failed:
        return "badge badge-danger mr-1";
      case TicketsStatus.MiddleWareOffline:
        return "badge badge-danger mr-1";
      case TicketsStatus.CommandTimeOutReached:
        return "badge badge-danger mr-1";
      case TicketsStatus.Cancelled:
        return "badge badge-danger mr-1";
    }
  }

  static getCommandType(commandType: CommandType) {
    switch (commandType) {
      case CommandType.RelayOff:
        return "Desativar o Relé";
      case CommandType.RelayOn:
        return "Ativar o Relé";
      case CommandType.RelayStatus:
        return "Obter estado do Réle";
      case CommandType.DisableCollectiveCut:
        return "Autorizar Abertura de Portas";
      case CommandType.EnableCollectiveCut:
        return "Desativar Abertura de Porta";
      case CommandType.Rollback:
        return "RollBack";
      case CommandType.ListMetersSmcSerial:
        return "Requisitando Medidores do SMC";
      case CommandType.UpdateDateTime:
        return "Atualizar Data e Hora";
      case CommandType.SendKeyAk:
        return "Envio de Chaves Ak para os medidores";
      case CommandType.SendKeyEk:
        return "Envio de Chaves Ek para os medidores";
      case CommandType.InstantMeasure:
        return "Requisitar Medidas Instantâneas";
      case CommandType.NicReset:
        return "Reset da NIC";
    }
  }

  public static stringToJson(serviceNote: ReceiptDTO) {
    if (
      serviceNote.dataRequest === null ||
      !serviceNote.dataRequest.toString().includes("}")
    )
      return null;
    return JSON.parse(serviceNote.dataRequest);
  }

  public static stringToJsonResponse(serviceNote: ReceiptDTO): JSON {
    if (
      serviceNote.dataResponse === null ||
      !serviceNote.dataResponse.toString().includes("}")
    )
      return null;
    return JSON.parse(serviceNote.dataResponse);
  }
}
