import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UserType } from "../../../core/models/UserDTO";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "../../../security/auth.service";
import { HandleErrorService } from "../../services/handle-error.service";

@Component({
  selector: "app-buttons-relay-operation",
  templateUrl: "./buttons-relay-operation.component.html",
  styleUrls: ["./buttons-relay-operation.component.scss"],
})
export class ButtonsRelayOperationComponent {
  @Input() checkedMeters: string[];
  @Input() meterSerial: string;
  @Input() commandQueue: number;
  @Input() isLoading = false;
  @Input() meter = null;


  @Input() requestingRelayStatus: boolean;
  @Input() requestingAllRelayStatus: boolean;
  @Input() relayDeactivating: boolean;
  @Input() relayActivating: boolean;
  @Input() showOnlyRequest = false;
  @Input() showRequestAllStatus = false;
  @Input() requestingSmcMeters = false;

  @Output() relayOn = new EventEmitter();
  @Output() relayOff = new EventEmitter();
  @Output() getRelayStatus = new EventEmitter();
  @Output() requestAll = new EventEmitter();
  @Output() requestMeters = new EventEmitter();

  public hardOperations = [UserType.ADMIN];
  public canChangeRelayStatus = [UserType.ADMIN, UserType.OPERATOR];

  constructor(
    private modalService: NgbModal,
    public authService: AuthService,
    private handleService: HandleErrorService
  ) { }

  confirmRelayOperation(content) {
    if (this.authService) {
      this.modalService.open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
      });
    } else {
      this.handleService.handle(new HttpErrorResponse({ status: 401 }));
    }
  }

  allRelayOn() {
    this.finishOperation();
    if (this.checkedMeters) {
      this.relayOn.emit(this.checkedMeters.slice());
    } else {
      this.relayOn.emit();
    }
  }

  allRelayOff() {
    this.finishOperation();
    if (this.checkedMeters) {
      this.relayOff.emit(this.checkedMeters.slice());
    } else {
      this.relayOff.emit();
    }
  }

  allGetRelay() {
    if (this.checkedMeters) {
      this.getRelayStatus.emit(this.checkedMeters.slice());
    } else {
      this.getRelayStatus.emit();
    }
  }
  requestAllStatus() {
    this.requestAll.emit();
  }

  requestSmcMeters() {
    this.requestMeters.emit();
  }

  finishOperation() {
    this.modalService.dismissAll();
  }
}
