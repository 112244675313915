import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnInit,
} from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Utils } from "src/app/shared/utils";
import {
  ReceiptDTO,
  Companys,
  ActionType,
  SchedulingStatusType,
} from "src/app/core/models/ReceiptDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { FileService } from "../../services/file.service";
import { ReadingDTO } from "src/app/core/models/ReadingDTO";
import { ServicesNoteServices } from "../../services/services-notes.service";
import { HandleErrorService } from "../../services/handle-error.service";
import { Status } from "src/app/core/models/TicketsDTO";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { TranslateService } from "@ngx-translate/core";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";

@Component({
  selector: "app-services-notes-table",
  templateUrl: "./services-notes-table.component.html",
  styleUrls: ["./services-notes-table.component.scss"],
})
export class ServicesNotesTableComponent implements OnInit {
  @Input() notes: ReceiptDTO[];
  @Input() isLoading = true;
  @Input() isReloading = false;
  @Input() page: PaginationResponseDTO = new PaginationResponseDTO();

  @Output() filter = new EventEmitter();
  @Output() reload = new EventEmitter();

  @ViewChild("notesTable", { static: false }) notesTable: DatatableComponent;

  Utils = Utils;
  Receipt = ReceiptDTO;
  ReadingDTO = ReadingDTO;
  ActionType = ActionType;

  public Companys = Companys;
  public companyNumber = Math.ceil(Object.keys(Companys).length / 2) - 1;

  public TICKECTS_STATUS;
  selectedTicketStatus: Status = null;

  public startDate = null;
  public endDate = null;
  public serviceCode: string = null;
  public currentCompany: string = null;
  public pageSize = 15;
  public currentPage = 1;
  public orderBy = 'desc';
  public sortBy = 'Deadline';

  public filters = {
    startDate: this.startDate,
    endDate: this.endDate,
    company: this.currentCompany,
    code: this.serviceCode,
    pageSize: this.pageSize,
    page: this.currentPage,
    orderBy: this.orderBy,
    sortBy: this.sortBy,
    status: this.selectedTicketStatus
  };

  enumMappersDTO: EnumMapperDTO[];
  constructor(
    private fileService: FileService,
    private modalService: NgbModal,
    private notesServices: ServicesNoteServices,
    private handleErrorService: HandleErrorService,
    private translateInCode: TranslateInCodeService,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    await this.getTranslate();

    this.translate.onLangChange.subscribe(async (e) => {
      await this.getTranslate();
    });
  }

  async getTranslate() {
    this.enumMappersDTO = await this.translateInCode
      .getTranslateEnunsInArray('ServicesNotes.Enuns', [ActionType, SchedulingStatusType]);
    this.TICKECTS_STATUS = this.enumMappersDTO[1].getAll();
  }

  toggleExpandRow(row) {
    this.notesTable.rowDetail.toggleExpandRow(row);
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  filterByDate() {
    this.filters["endDate"] = this.endDate;
    this.filters["startDate"] = this.startDate;

    this.emiteValue();
  }

  finishOperation() {
    this.modalService.dismissAll();
  }

  filterByServiceNote(note) {
    this.serviceCode = note;
    this.filters.code = this.serviceCode;
    this.endDate = null;
    this.startDate = null;
    this.filters.endDate = this.endDate;
    this.filters.startDate = this.startDate;
    this.emiteValue();
  }

  selectCompany(company) {
    this.currentCompany = company;
    this.filters["company"] = this.currentCompany;
  }

  filterByCompany() {
    this.emiteValue();
  }

  changeSizeValue(value) {
    this.pageSize = value;
    this.currentPage = 1;
    this.filters.pageSize = this.pageSize;
    this.filters.page = this.currentPage;
    this.emiteValue();
  }

  changePage(page) {
    this.currentPage = page.page;
    this.filters.page = this.currentPage;

    this.emiteValue();
  }


  filterByTicketStatus() {
    this.filters.status = this.selectedTicketStatus;
    this.emiteValue();

  }

  onSort(prop) {
    this.orderBy = prop.sorts[0].dir;
    this.sortBy = prop.sorts[0].prop;
    this.filters.sortBy = this.sortBy;
    this.filters.orderBy = this.orderBy;
    this.currentPage = 1;
    this.filters.page = this.currentPage;
    this.emiteValue();
  }

  removeFilter() {
    this.resetValues();
  }

  saveFile() {
    this.notesServices.getSchedulesByDate(this.page.totalCount, 1, this.startDate, this.endDate, this.serviceCode, this.currentCompany, this.selectedTicketStatus ? this.selectedTicketStatus.toString() : null, this.orderBy, this.sortBy).toPromise()
      .then(response => {
        this.fileService.buildAndSaveServiceNotes(response['data'], "Notas de Serviços");
      }).catch(error => {
        console.log(error);
        this.handleErrorService.handle(error);
      });
  }

  private emiteValue() {
    this.filter.emit(this.filters);
    this.modalService.dismissAll();
  }

  private resetValues() {
    this.currentPage = 1;
    this.startDate = null;
    this.endDate = null;
    this.serviceCode = null;
    this.currentCompany = null;
    this.selectedTicketStatus = null;
    this.orderBy = 'desc';
    this.sortBy = 'Deadline';

    this.filters.orderBy = this.orderBy;
    this.filters.sortBy = this.sortBy;
    this.filters.code = this.serviceCode;
    this.filters.page = this.currentPage;
    this.filters.endDate = this.endDate;
    this.filters.startDate = this.startDate;
    this.filters.company = this.currentCompany;
    this.filters.status = this.selectedTicketStatus;
    this.emiteValue();
  }
  reloadNotes() {
    this.reload.emit();
  }
}
