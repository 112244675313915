import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NgForm } from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown/multiselect.model";
import * as XLS from "xlsx";
// DTO'S
import {
  MeterDTO,
  MeterManufacturer,
  MeterPhase,
} from "src/app/core/models/MeterDTO";
import { MeterModelDTO } from "src/app/core/models/MeterModelDTO";
import { RateTypeDTO } from "src/app/core/models/RateTypeDTO";
import { SmcDTO } from "src/app/core/models/SmcDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { RegistersDTO } from "src/app/core/models/RegistersDTO";
import { SmcModelDTO } from "src/app/core/models/SmcModelDTO";
import { TagsDTO } from "src/app/core/models/TagsTDO";
// SERVICES
import { HandleErrorService } from "src/app/shared/services/handle-error.service";
import { MetersService } from "src/app/shared/services/meters.service";
import { SmcsService } from "src/app/shared/services/smcs.service";
import { RegistersService } from "src/app/shared/services/registers.service";
import { TagsServices } from "src/app/shared/services/tags.service";
// UTILS
import { Utils } from "src/app/shared/utils";
import { MessageService } from "../../services/message.service";
import { FileService } from "../../services/file.service";
import { environment } from "src/environments/environment";

export const METERCONECTIONPHASE = {
  0: "A",
  1: "B",
  2: "C",
  3: "AN",
  4: "BN",
  5: "CN",
  6: "AB",
  7: "AC",
  8: "BC",
  9: "ABN",
  10: "ACN",
  11: "BCN",
  12: "ABC",
  13: "ABCN",
  14: "A3N",
  15: "B3N",
  16: "C3N",
  17: "B3",
  18: "A3",
  19: "C3",
  20: "A2N",
  21: "B2N",
  22: "C2N",
};

@Component({
  selector: "app-meter-register-table",
  templateUrl: "./meter-register-table.component.html",
  styleUrls: ["./meter-register-table.component.scss"],
})
export class MeterRegisterTableComponent implements OnInit {
  @Input() meters: MeterDTO[] = [];
  @Input() phaseCount: number;
  @Input() maxMetersCount: number;
  @Input() page: PaginationResponseDTO;
  @Input() showCheck: boolean;
  @Input() showCreate = true;
  @Input() showView = true;
  @Input() showEdit = true;
  @Input() showDelete = true;
  @Input() isLoading: boolean;
  @Input() isReloading = false;

  @Output() meterExclusion = new EventEmitter();
  @Output() meterEdit = new EventEmitter();
  @Output() meterCreate = new EventEmitter();
  @Output() filterMeter = new EventEmitter();
  @Output() reloadMeters = new EventEmitter();
  @Output() massEdit = new EventEmitter();

  jsonMetersList: any = null;

  @ViewChild("ngxTable", { static: true }) table: DatatableComponent;

  allChecked = false;
  isEditing = false;
  removeSufixAndPrefix = false;
  massEditing = false;
  isLoadingAllMeters = false;

  public METERCONECTIONPHASE = [
    { name: "A", value: 0 },
    { name: "B", value: 1 },
    { name: "C", value: 2 },
    { name: "AN", value: 3 },
    { name: "BN", value: 4 },
    { name: "CN", value: 5 },
    { name: "AB", value: 6 },
    { name: "AC", value: 7 },
    { name: "BC", value: 8 },
    { name: "ABN", value: 9 },
    { name: "ACN", value: 10 },
    { name: "BCN", value: 11 },
    { name: "ABC", value: 12 },
    { name: "ABCN", value: 13 },
    { name: "A3N", value: 14 },
    { name: "B3N", value: 15 },
    { name: "C3N", value: 16 },
    { name: "B3", value: 17 },
    { name: "A3", value: 18 },
    { name: "C3", value: 19 },
    { name: "A2N", value: 20 },
    { name: "B2N", value: 21 },
    { name: "C2N", value: 22 },
  ];
  MeterDTO = MeterDTO;
  Utils = Utils;
  MeterPhase = MeterPhase;
  MeterManufacturer = MeterManufacturer;
  RateTypes: RateTypeDTO[] = [];
  models: any[] = [];
  meterModels: MeterModelDTO[] = [];
  smcModels: SmcModelDTO[] = [];
  SMC: SmcDTO[] = [];
  Registers: RegistersDTO[] = [];
  tags: TagsDTO[] = [];

  meter: MeterDTO;
  deleteMeter: MeterDTO;

  checkedMeters: string[] = [];

  public dropDownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "code",
    textField: "description",
    selectAllText: "Selecionar todos",
    unSelectAllText: "Remover Todos",
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: "Nenhum registro encontrado",
    searchPlaceholderText: "Procurar registro pelo nome",
  };

  public tagsDropDownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "visibleId",
    textField: "keyword",
    selectAllText: "Selecionar todas as tags",
    unSelectAllText: "Remover todas as tags.",
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: "Nenhuma tag encontrado",
    searchPlaceholderText: "Procurar tag pelo nome",
  };
  public meterPhaseDropDownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "value",
    textField: "name",
    allowSearchFilter: false,
    noDataAvailablePlaceholderText: "Nenhuma fase encontrado",
  };

  public startDate = "";
  public endDate = "";
  public searchString = "";
  public sortOrder = "";
  public currentPage = 0;
  public pageSize = 15;
  placeHolderLabel =
    "Pesquisar pelo serial, código de instalação , EUI do modem ou Fabricante";

  private filter = {
    pageSize: this.pageSize,
    sortOrder: this.sortOrder,
    searchString: this.searchString,
    currentPage: this.currentPage,
    startDate: this.startDate,
    endDate: this.endDate,
  };
  public fileURL = "./assets/models/modelo de cadastro dos medidores.xlsx";

  constructor(
    private modalService: NgbModal,
    private meterServices: MetersService,
    private smcService: SmcsService,
    private registersService: RegistersService,
    private handleService: HandleErrorService,
    private messageServices: MessageService,
    private _tagsServices: TagsServices,
    public fileService: FileService,
  ) {}

  ngOnInit() {
    this.getMetersType();
    this.getMetersModels();
    this.getSmcModels();
    this.getAllSMC();
    this.getAllRegisters();
    this.getAllTags();
    this.meter = new MeterDTO();
    this.deleteMeter = new MeterDTO();
  }

  keys(Enumerator): Array<string> {
    if (Enumerator) {
      const keys = Object.keys(Enumerator);
      return keys.slice(keys.length / 2);
    }
  }

  getAllSMC() {
    this.smcService
      .getSmcs()
      .toPromise()
      .then((smcs: SmcDTO[]) => {
        this.SMC = smcs;
      })
      .catch((error) => {
        this.handleService.handle(error);
      });
  }

  getAllRegisters() {
    this.registersService
      .getRegisters()
      .toPromise()
      .then((registers: RegistersDTO[]) => {
        this.Registers = registers;
      })
      .catch((error) => {
        this.handleService.handle(error);
      });
  }

  getAllTags() {
    this._tagsServices
      .getTags()
      .toPromise()
      .then((response: TagsDTO[]) => {
        this.tags = response;
      })
      .catch((error) => {
        this.handleService.handle(error);
      });
  }

  selectOnlyId(event) {
    this.meter.meterRegisters.push(event);
  }
  setConnectionPhase(value) {
    this.meter.connectionPhase = value.value;
  }

  readFile(event: any) {
    const file: File = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = () => {
      const arrayBuffer: any = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      let array = new Array();

      for (let index = 0; index !== data.length; ++index) {
        array[index] = String.fromCharCode(data[index]);
      }
      const listOfKeys = array.join("");
      const workbook = XLS.read(listOfKeys, { type: "binary" });
      const fileName = workbook.SheetNames[0];
      const workSheet = workbook.Sheets[fileName];

      this.jsonMetersList = XLS.utils.sheet_to_json(workSheet);
    };
  }

  realoadEvent() {
    this.reloadMeters.emit();
  }

  registerMeters() {
    this.isLoading = true;
    this.removePrefixAndSufix();
    this.jsonMetersList.forEach((meter: MeterDTO, index) => {
      let registers: any[] = [];
      let tags: any[] = [];
      const model: any = meter.meterModel;
      const rateType: any = meter.rateType;
      if (meter.meterRegisters !== undefined && meter.meterRegisters !== null)
        registers.push(...meter.meterRegisters.toString().trim().split(";"));
      if (meter.tags !== undefined && meter.tags.length > 0)
        tags.push(...meter.tags.toString().replace(/\s+/g, "").split(";"));

      meter.meterRegisters = RegistersDTO.getRegistersByName(
        this.Registers,
        registers,
      );
      const phase: any = meter.phase;
      if (phase !== undefined) meter.phase = MeterDTO.getPhase(phase);
      else {
        this.messageServices.showError(
          "Fase Obrigatório",
          "Verique o Arquivo e preencha a fase do medidor!",
        );
        this.isLoading = false;
      }
      if (meter.longitude === undefined || meter.latitude === undefined) {
        this.messageServices.showInfinitError(
          "Latitude/Longitude Obrigatorio",
          `O medidor de serial ${meter.serial} não possui latitude ou longitude!`,
        );
        this.isLoading = false;
        return;
      }

      if (rateType !== undefined)
        meter.rateType = RateTypeDTO.getRateTypeByName(
          rateType,
          this.RateTypes,
        );
      else {
        this.messageServices.showError(
          "RateType Obrigatório",
          "Verique o Arquivo e preencha o rateType do medidor!",
        );
        this.isLoading = false;
      }
      meter.tags = [];
      tags.forEach((tag) => {
        meter.tags.push(TagsDTO.getTagsByVisibleIdString(tag, this.tags));
      });
      if (model !== undefined)
        meter.meterModel = MeterModelDTO.getModelsByName(model, this.models);
      else {
        this.messageServices.showError(
          "Modelo Obrigatório",
          "Verique o Arquivo e preencha o modelo do medidor!",
        );
        this.isLoading = false;
      }
      if (!this.massEditing) {
        setTimeout(() => {
          this.meterCreate.emit(meter);
        }, 1000 * index);
      }
    });
    if (this.massEditing) {
      this.massEdit.emit(this.jsonMetersList);
      this.massEditing = false;
    }
  }

  modalMeter(content, row?) {
    this.meter.smc = new SmcDTO();
    if (this.isEditing && row) {
      this.models = this.smcModels;
      this.meterServices
        .getMeterBySerial((<MeterDTO>row).serial)
        .toPromise()
        .then((meter: MeterDTO) => {
          if (meter.smc === null) {
            this.models = this.meterModels;
            meter.smc = new SmcDTO();
          }
          this.meter = meter;
          this.modalService.open(content, {
            ariaLabelledBy: "modal-basic-title",
            centered: true,
            backdrop: "static",
            keyboard: false,
          });
        })
        .catch((error) => this.handleService.handle(error));
    } else {
      this.modalService.open(content, {
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        backdrop: "static",
        keyboard: false,
      });
    }
  }

  getMetersModels() {
    this.meterServices
      .getMetersModels()
      .toPromise()
      .then((models: MeterModelDTO[]) => {
        this.meterModels = models;
        this.models = this.meterModels;
      })
      .catch((error) => {
        this.handleService.handle(error);
      });
  }

  getSmcModels() {
    this.smcService
      .getSmcModels()
      .toPromise()
      .then((response: SmcModelDTO[]) => {
        this.smcModels = response;
      })
      .catch((error) => {
        this.handleService.handle(error);
      });
  }

  private removePrefixAndSufix() {
    if (this.removeSufixAndPrefix) {
      this.jsonMetersList.forEach((meter: MeterDTO) => {
        meter.serial = Utils.removePrefixAndSufix(meter.serial);
      });
    }
  }

  getMetersType() {
    this.meterServices
      .getRatesType()
      .toPromise()
      .then((types: RateTypeDTO[]) => {
        this.RateTypes = types;
      })
      .catch((err) => {
        this.handleService.handle(err);
      });
  }

  confirmMeterExclusion(content, row) {
    this.finishOperation();
    this.deleteMeter = <MeterDTO>row;
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  createUpdate(form: NgForm) {
    if (this.meter.tags.length > 0) {
      let tags = [];
      this.meter.tags.forEach((_tag) => {
        tags.push(TagsDTO.getTagsByVisibleId(_tag, this.tags));
      });
      this.meter.tags = tags;
    }
    if (this.isEditing) {
      this.meterEdit.emit(this.meter);
    } else {
      const currentModel = this.models.find((model) =>
        this.meter.hasTransparent
          ? model.manufacturer.name.toLowerCase() ==
            environment.nansenTransparent.toLowerCase()
          : model.name.toLowerCase() ===
            this.meter.meterModel.name.toLowerCase(),
      );
      this.meter.meterModel = currentModel;

      this.meterCreate.emit(this.meter);
    }
  }

  addNansenModel() {
    const modelsCopy = JSON.parse(JSON.stringify(this.models));
    
    this.meter.meterModel = modelsCopy.find(
      (m) =>
        m.manufacturer.name.toLowerCase() ===
        environment.nansenTransparent.toLowerCase(),
    );
  }

  transparentHandler(event) {
    if (event) this.addNansenModel();
  }

  smcSerialChange(serial) {
    if (serial) {
      this.models = this.smcModels;
      this.meter.meterModel = this.models[0];
    } else {
      this.models = this.meterModels;
      this.meter.meterModel = this.models[0];
    }
  }

  delete() {
    this.meterExclusion.emit(this.deleteMeter);
  }

  finishOperation(form?: NgForm) {
    if (form) {
      form.reset();
    }
    this.modalService.dismissAll();
    this.meter = new MeterDTO();
    this.isLoading = false;
    this.isEditing = false;
    this.massEditing = false;
    this.isLoadingAllMeters = false;
  }

  openModal(content) {
    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  verifyMeterPhaseDisable(key) {
    if (this.phaseCount + +key > this.maxMetersCount) {
      return true;
    } else {
      return false;
    }
  }

  sortByProp(prop) {
    const sort = prop.sorts[0];
    this.currentPage = 0;
    this.sortOrder = `${sort.prop}-${sort.dir}`;
    this.filter["currentPage"] = this.currentPage;
    this.filter["sortOrder"] = this.sortOrder;
    this.emiteValue(this.filter);
  }

  changePage(page) {
    this.currentPage = page.page - 1;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  changeSizeValue(value) {
    this.pageSize = value;
    this.currentPage = 0;
    this.filter["pageSize"] = this.pageSize;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  filterByDate() {
    this.filter["startDate"] = this.startDate;
    this.filter["endDate"] = this.endDate;
    this.currentPage = 0;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
    this.closeModal();
  }

  private closeModal() {
    this.modalService.dismissAll();
  }

  removeFilter() {
    this.reseteValues();
    this.emiteValue(this.filter);
    this.closeModal();
  }

  updateSearchString(searchValue) {
    this.currentPage = 0;
    this.searchString = searchValue;
    this.filter["searchString"] = this.searchString;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  private reseteValues() {
    this.sortOrder = "";
    this.searchString = "";
    this.startDate = "";
    this.endDate = "";
    this.currentPage = 0;
    this.filter["currentPage"] = this.currentPage;
    this.filter["endDate"] = this.endDate;
    this.filter["startDate"] = this.startDate;
    this.filter["sortOrder"] = this.sortOrder;
    this.filter["searchString"] = this.searchString;
  }

  private emiteValue(value) {
    this.filterMeter.emit(value);
    document.getElementById("topMeterComissioned").scrollIntoView(true);
  }

  downloadAllMeters() {
    this.isLoadingAllMeters = true;
    this.meterServices
      .getMeters("serial-desc", "", 1, this.page.numberOfItems)
      .toPromise()
      .then((response) => {
        const meters: MeterDTO[] = <MeterDTO[]>response.listOfItems;
        const rows = this.processMeters(meters);
        const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
        workSheet["!cols"] = [{ width: 45 }];

        const workbook: XLS.WorkBook = {
          Sheets: {
            Medidores: workSheet,
          },
          SheetNames: ["Medidores"],
        };
        const excelBuffer: any = XLS.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        this.fileService.saveFileAsExcel(excelBuffer, "Todos os Medidores");

        this.isLoadingAllMeters = false;
      })
      .catch((error) => {
        this.handleService.handle(error);
        this.isLoadingAllMeters = false;
      });
  }

  private getConnectionPhase(phase: number) {
    return METERCONECTIONPHASE[phase];
  }

  private processMeters(meters: MeterDTO[]) {
    let metersList = [];

    if (this.massEditing) {
      meters.forEach((_meter) => {
        let tags: string = "";
        let registers: string = "";
        _meter.tags.forEach((tag) => {
          tags += tag.visibleId + ";";
        });
        if (tags.slice(tags.length - 1) === ";") {
          tags = tags.slice(0, tags.length - 1);
        }
        _meter.meterRegisters.forEach((_register) => {
          registers += _register.code + ";";
        });
        if (registers.slice(registers.length - 1) === ";")
          registers = registers.slice(0, registers.length - 1);

        const meter = {
          phase: MeterDTO.getPhaseValue(_meter),
          meterModel: _meter.meterModel.name,
          rateType: _meter.rateType.name,
          smc: _meter.smc !== null ? _meter.smc.serial : null,
          serial: _meter.serial,
          meterResgisters: registers,
          latitude: String(_meter.latitude).toString(),
          longitude: String(_meter.longitude).toString(),
          installation: _meter.installation,
          tags: tags,
          connectionPhase: this.getConnectionPhase(_meter.connectionPhase),
        };
        metersList.push(meter);
      });
    } else {
      meters.forEach((_meter) => {
        let tags: string = "";
        let registers: string = "";
        _meter.tags.forEach((tag) => {
          tags += tag.keyword + ";";
        });
        if (tags.slice(tags.length - 1) === ";") {
          tags = tags.slice(0, tags.length - 1);
        }
        _meter.meterRegisters.forEach((_register) => {
          registers += _register.code + ";";
        });
        if (registers.slice(registers.length - 1) === ";")
          registers = registers.slice(0, registers.length - 1);

        const meter = {
          Fase: MeterDTO.getPhaseValue(_meter),
          Modelo: _meter.meterModel.name,
          Smc: _meter.smc !== null ? _meter.smc.serial : null,
          Serial: _meter.serial,
          Posição: _meter.position,
          ["Versão de Firmware"]:
            _meter.modem !== null ? _meter.modem.firmwareVersion : "--",
          Tags: tags,
          Latitude: _meter.latitude,
          Longitude: _meter.longitude,
          ["Estado do Relé"]: MeterDTO.getRelayStatusValue(
            _meter.accountantStatus,
          ),
          ["Comissionamento"]: _meter.comissioned
            ? "Comissionado"
            : "Não Comissionado",
          ["Código de Instalação"]: _meter.installation,
          ["Estado de Comunicação"]: _meter.online ? "Online" : "Offline",
          DeviceEui: _meter.modem !== null ? _meter.modem.deviceEui : null,
          Fabricante:
            _meter.meterModel !== null
              ? _meter.meterModel.manufacturer.name
              : null,
        };
        metersList.push(meter);
      });
    }
    return metersList;
  }
}
