import { QuicklinkStrategy } from 'ngx-quicklink';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./security/auth.guard";
import { BlankLayoutComponent } from "./shared/components/layouts/blank-layout/blank-layout.component";
import { AdminLayoutSidebarLargeComponent } from "./shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component";
const adminRoutes: Routes = [
  {
    path: "entrar",
    loadChildren: "./views/sessions/sessions.module#SessionsModule",
  },
  {
    path: "medidores",
    loadChildren: "./views/meters/meters.module#MetersModule",
  },
  {
    path: "smcs",
    loadChildren: "./views/smcs/smcs.module#SmcsModule",
  },
  {
    path: "grupos",
    loadChildren: "./views/groups/groups.module#GroupsModule",
  },
  {
    path: "usuarios",
    loadChildren: "./views/users/users.module#UsersModule",
  },
  {
    path: "notas-de-servico",
    loadChildren:
      "./views/services-notes/services-notes.module#ServicesNotesModule",
  },
  {
    path: "firmware",
    loadChildren: "./views/cadastro/firmware/firmware.module#FirmWareModule",
  },
  {
    path: "chaves",
    loadChildren: "./views/cadastro/keys/keys.module#KeysModule",
  },
  {
    path: "cadastro-transformadores",
    loadChildren: "./views/cadastro/transformers/transformers.module#TransformersModule",
  },
  {
    path: "tipos-de-tarifas",
    loadChildren: "./views/cadastro/rate-types/rate-types.module#RateTypesModule",
  },
  {
    path: "cadastro-smcs",
    loadChildren: "./views/cadastro/smcs/smcs.module#SmcsCadastroModule",
  },
  {
    path: 'comando-em-massa',
    loadChildren: './views/cluster-commands/cluster-commands.module#ClusterCommandsModule'
  },
  {
    path: "cadastros-medidores",
    loadChildren: "./views/cadastro/meters/meters.module#MetersRegisterModule",
  },
  {
    path: "aplicativo",
    loadChildren: "./views/app/app.module#AppModule",
  },
  {
    path: "dashboard",
    loadChildren: "./views/dashboard/dashboard.module#DashBoardModule",
    data: {
      preload: false
    }
  },
  {
    path: "comando-agendados",
    loadChildren: "./views/schedules-commands/schedules-commands.module#SchedulesCommandsModule",
  },
  {
    path: "balanco",
    loadChildren: "./views/balance/balance.module#BalanceModule",
  },
  {
    path: "relatorio",
    loadChildren: "./views/tickets/tickets.module#TicketsModule",
  },
  {
    path: "auditoria",
    loadChildren: "./views/audit/audit.module#AuditModule"
  }
];

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    redirectTo: "dashboard/leituras",
    pathMatch: "full",
    data: {
      preload: false
    }
  },
  {
    path: "",
    canActivate: [AuthGuard],
    redirectTo: "firmware",
    pathMatch: "full",
  },
  {
    path: "",
    canActivate: [AuthGuard],
    redirectTo: "smcs",
    pathMatch: "full",
  },
  {
    path: "",
    canActivate: [AuthGuard],
    redirectTo: 'comando-em-massa',
    pathMatch: 'full'
  },
  {
    path: "",
    canActivate: [AuthGuard],
    redirectTo: "groups",
    pathMatch: "full",
  },
  {
    path: "",
    canActivate: [AuthGuard],
    redirectTo: "services-notes",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessao",
        canActivate: [AuthGuard],
        loadChildren: "./views/sessions/sessions.module#SessionsModule",
      },
    ],
  },
  {
    path: "",
    component: BlankLayoutComponent,
    children: [
      {
        path: "others",
        loadChildren: "./views/others/others.module#OthersModule",
      },
    ],
  },
  {
    path: "",
    canActivate: [AuthGuard],
    component: AdminLayoutSidebarLargeComponent,
    children: adminRoutes,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: QuicklinkStrategy })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
