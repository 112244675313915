import { CommandName } from "protractor";
import { CommunicationStatus } from "src/app/core/models/MeterDTO";

export enum TicketsStatus {
  Waiting = 0,
  Executing = 1,
  Finished = 2,
  Cancelled = 11,
  PartiallyFinished = 3,
  Failed = 8,
  Interupted = 4,
  MiddlewareNotResponding = 5,
  FailedToStart = 6,
  FailedToContinue = 7,
  MiddleWareOffline = 9,
  CommandTimeOutReached = 10,
  WaitingScheduleDateTime = 12,
}
export interface ITicketsDTO {
  serial: string;
  ticketId: string;
  user: string;
  initialDate: Date;
  finishDate: Date;
  interval: number;
  ticketStatus: TicketsStatus;
  status: Status;
  statusCommand: StatusCommand;
  commandType: CommandType;
  commandTickets: CommandTickets[];
  serialMeter: string;
  ticketCreationDate: Date;
  updatedAt: Date;
}

export class CommandTickets {
  pdu: string;
  commandId: string;
  initialDate: Date;
  finishDate: Date;
  attempt: number;
  status: Status;
  statusCommand: StatusCommand;
  answer: string;
  command: CommandType;
  communicationStatus: CommunicationStatus;
}

export enum Status {
  Waiting = "Waiting",
  Executing = "Executing",
  Finished = "Finished",
  Failed = "Failed",
  Cancelled = "Cancelled",
}

export enum CommandType {
  RelayOff = "RelayOff",
  RelayOn = "RelayOn",
  RelayStatus = "RelayStatus",
  EnableCollectiveCut = "EnableCollectiveCut",
  DisableCollectiveCut = "DisableCollectiveCut",
  Rollback = "Rollback",
  SendKeyAk = "SendKeyAk",
  SendKeyEk = "SendKeyEk",
  ListMetersSmcSerial = "ListMetersSmcSerial",
  UpdateDateTime = "UpdateDateTime",
  SetSmiVoltage = "SetSmiVoltage",
  GetSmiVoltage = "GetSmiVoltage",
  SetDisplayConfiguration = "SetDisplayConfiguration",
  SetDisplayConfigurationManual = "SetDisplayConfigurationManual",
  MeterRecoverEventQeeIndicator = "MeterRecoverEventQeeIndicator",
  SetQualityMeasureParams = "SetQualityMeasureParams",
  GetQeeBuffer = "GetQeeBuffer",
  GetEnergyTotalizer = "GetEnergyTotalizer",
  SetDatetime = "SetDatetime",
  InstantMeasure = "InstantMeasure",
  NicReset = "NicReset",
  SetCurrent = "SetCurrent",
  GetQEEIndicator = "GetQEEIndicator",
  UplinkPeriod = "UplinkPeriod",
  RecoverMassMemory = "RecoverMassMemory",
  RecoverFrozenData = "RecoverFrozenData",
  RecoverEventQeeIndicator = "RecoverEventQeeIndicator",
  RecoverGbtMessage = "RecoverGbtMessage",
  RequestRelayStatusAllSmc = "RequestRelayStatusAllSmc",
  ImportFrozenData = "ImportFrozenData",
  ImportMassMemory = "ImportMassMemory",
  ImportQEEIndicator = "ImportQEEIndicator",
  RequestDoorStatus = "RequestDoorStatus",
  RelayOnAllMetersOfSmc = "RelayOnAllMetersOfSmc",
  MeterAddedToSmc = "MeterAddedToSmc",
  MeterRemovedFromSmc = "MeterRemovedFromSmc",
  GetVoltageOutShort = "GetVoltageOutShort",
  GetVoltageOutLong = "GetVoltageOutLong",
  ImportVoltageOutAlarm = "ImportVoltageOutAlarm",
  MarkAlarmAsRead = "MarkAlarmAsRead",
  SetIntervalOfQee = "SetIntervalOfQee",
  SetIntervalOLongOutEvent = "SetIntervalOLongOutEvent",
  SetStandardFrozenData = "SetStandardFrozenData",
  DoorStatusAbsolute = "DoorStatusAbsolute",
  MassRegistration = "MassRegistration",
  ChangeNetworkType = "ChangeNetworkType",
  GetNetworkType = "GetNetworkType",
  GetNetworkInfo = "GetNetworkInfo",
  ChangeNetworkTech = "ChangeNetworkTech",
  GetNetworkOperator = "GetNetworkOperator",   
  ChangeApn = "ChangeApn",
}

export enum StatusCommand {
  INCOMPLETE = "INCOMPLETE",
  SUCESS = "SUCESS",
  HARDWARE_FAULT = "HARDWARE_FAULT",
  TEMPORARY_FAILURE = "TEMPORARY_FAILURE",
  READ_WRITE_DENIED = "READ_WRITE_DENIED",
  OBJECT_UNDEFINED = "OBJECT_UNDEFINED",
  WAITING = "WAITING",
  OBJECT_CLASS_INCONSISTENT = "OBJECT_CLASS_INCONSISTENT",
  OBJECT_UNAVAILABLE = "OBJECT_UNAVAILABLE",
  TYPE_UNMATCHED = "YPE_UNMATCHED",
  SCOPE_OF_ACCESS_VIOLATED = "SCOPE_OF_ACCESS_VIOLATED",
  DATA_BLOCK_UNAVAILABLE = "DATA_BLOCK_UNAVAILABLE",
  LONG_GET_ABORTED = "LONG_GET_ABORTED",
  NO_LONG_GET_IN_PROGRESS = "NO_LONG_GET_IN_PROGRESS",
  LONG_SET_ABORTED = "LONG_SET_ABORTED",
  NO_LONG_SET_IN_PROGRESS = "NO_LONG_SET_IN_PROGRESS",
  DAT_BLOCK_NUMBER_INVALID = "DAT_BLOCK_NUMBER_INVALID",
  INVALID_ITEM = "INVALID_ITEM",
  OTHER_REASON = "OTHER_REASON",
  INCORRECT_KEYS = "INCORRECT_KEYS",
  NO_COMISSIONED = "NO_COMISSIONED",
  TIMEOUT = "TIMEOUT",
  METERS_NOT_MATCH_WITH_RECEIVED = "METERS_NOT_MATCH_WITH_RECEIVED",
  INTERRUPTED_BY_COMMISSIONING = "INTERRUPTED_BY_COMMISSIONING",
  OPEN_DOOR = "OPEN_DOOR",
  SET_INTERVAL_OF_QEE = "SET_INTERVAL_OF_QEE",
  SET_INTERVAL_OF_LONG_OUT_VOLTAGE = "SET_INTERVAL_OF_LONG_OUT_VOLTAGE",
  SET_STANDARD_FROZEN_DATA = "SET_STANDARD_FROZEN_DATA",
  DOOR_STATUS_ABSOLUTE = "DOOR_STATUS_ABSOLUTE",
  SET_DISPLAY_CONFIGURATION = "SET_DISPLAY_CONFIGURATION",
}

export class TicketDTO implements ITicketsDTO {
  public serial: string;
  public ticketId: string;
  public user: string;
  public initialDate: Date;
  public finishDate: Date;
  public interval: number;
  public ticketStatus: TicketsStatus;
  public status: Status;
  public statusCommand: StatusCommand;
  public commandType: CommandType;
  public serialMeter: string;
  public commandTickets: CommandTickets[];
  public ticketCreationDate: Date;
  public updatedAt: Date;

  static getTicketStatus(ticketStatus: TicketsStatus) {
    switch (ticketStatus) {
      case TicketsStatus.Waiting:
        return "Aguardando";
      case TicketsStatus.Executing:
        return "Executando";
      case TicketsStatus.Finished:
        return "Finalizado";
      case TicketsStatus.PartiallyFinished:
        return "Finalizado Parcialmente";
      case TicketsStatus.Interupted:
        return "Interrompido";
      case TicketsStatus.MiddlewareNotResponding:
        return "Sem resposta do MiddleWare";
      case TicketsStatus.FailedToStart:
        return "Falha ao inicializar";
      case TicketsStatus.FailedToContinue:
        return "Erro durante o processamento";
      case TicketsStatus.Failed:
        return "Falhou";
      case TicketsStatus.MiddleWareOffline:
        return "MiddleWare Offline ou Indisponível";
      case TicketsStatus.CommandTimeOutReached:
        return "Excedeu Tempo de Execução";
      case TicketsStatus.Cancelled:
        return "Cancelado";
      case TicketsStatus.WaitingScheduleDateTime:
        return "Aguardando data e hora programada";
    }
  }

  static getTicketStatusClass(ticketStatus: TicketsStatus) {
    switch (ticketStatus) {
      case TicketsStatus.Waiting:
        return "badge badge-waiting mr-1";
      case TicketsStatus.Executing:
        return "badge badge-warning mr-1";
      case TicketsStatus.Finished:
        return "badge badge-success mr-1";
      case TicketsStatus.PartiallyFinished:
        return "badge badge-warning mr-1";
      case TicketsStatus.Interupted:
        return "badge badge-danger mr-1";
      case TicketsStatus.MiddlewareNotResponding:
        return "badge badge-danger mr-1";
      case TicketsStatus.FailedToStart:
        return "badge badge-danger mr-1";
      case TicketsStatus.FailedToContinue:
        return "badge badge-danger mr-1";
      case TicketsStatus.Failed:
        return "badge badge-danger mr-1";
      case TicketsStatus.MiddleWareOffline:
        return "badge badge-danger mr-1";
      case TicketsStatus.CommandTimeOutReached:
        return "badge badge-danger mr-1";
      case TicketsStatus.Cancelled:
        return "badge badge-danger mr-1";
      case TicketsStatus.WaitingScheduleDateTime:
        return "badge badge-info mr-1";
    }
  }

  static getStatus(status: Status): string {
    switch (status) {
      case Status.Executing:
        return "Executando";
      case Status.Waiting:
        return "Aguardando";
      case Status.Finished:
        return "Finalizado";
      case Status.Failed:
        return "Falhou";
      case Status.Cancelled:
        return "Cancelado";
    }
  }

  static getStatusClass(status: Status): string {
    switch (status) {
      case Status.Executing:
        return "badge badge-info mr-1";
      case Status.Waiting:
        return "badge badge-waiting mr-1";
      case Status.Finished:
        return "badge badge-success mr-1";
      case Status.Failed:
        return "badge badge-danger mr-1";
      case Status.Cancelled:
        return "badge badge-danger mr-1";
    }
  }

  static getCommandType(commandType: CommandType) {
    switch (commandType) {
      case CommandType.RelayOff:
        return "Desativar o Réle";
      case CommandType.RelayOn:
        return "Ativar o Réle";
      case CommandType.RelayStatus:
        return "Obter estado do Réle";
      case CommandType.DisableCollectiveCut:
        return "Autorizar Abertura de Portas";
      case CommandType.EnableCollectiveCut:
        return "Desativar Abertura de Porta";
      case CommandType.Rollback:
        return "RollBack";
      case CommandType.ListMetersSmcSerial:
        return "Requisitar Medidores do SMC";
      case CommandType.UpdateDateTime:
        return "Atualizar Data e Hora";
      case CommandType.SendKeyAk:
        return "Envio de Chaves de autenticação para os medidores";
      case CommandType.SendKeyEk:
        return "Envio de Chaves de criptografia para os medidores";
      case CommandType.InstantMeasure:
        return "Requisitar Energia Instantânea";
      case CommandType.NicReset:
        return "Reset da NIC";
      case CommandType.SetCurrent:
        return "Modificar corrente de corte";
      case CommandType.GetQEEIndicator:
        return "Solicitar parametros de qualidade";
      case CommandType.UplinkPeriod:
        return "Alterar Periodo de Leitura de Uplink";
      case CommandType.RecoverFrozenData:
        return "Recuperar Energia";
      case CommandType.RecoverMassMemory:
        return "Recuperar Memória de Massa";
      case CommandType.RecoverEventQeeIndicator:
        return "Recuperar indicadores de qualidade";
      case CommandType.RecoverGbtMessage:
        return "GBT";
      case CommandType.RequestRelayStatusAllSmc:
        return "Requisitar Estado do Relé de Todos os Medidores";
      case CommandType.ImportFrozenData:
        return "Importar Energia";
      case CommandType.ImportMassMemory:
        return "Importar Memória de Massa";
      case CommandType.ImportQEEIndicator:
        return "Importar Parâmetros de Qualidade";
      case CommandType.RequestDoorStatus:
        return "Obter Status do Corte Coletivo";
      case CommandType.RelayOnAllMetersOfSmc:
        return "Religar Todos os Clientes";
      case CommandType.SetDatetime:
        return "Setar Data e Hora do SMC";
      case CommandType.MeterAddedToSmc:
        return "Adicionar medidor ao smc (Sistema)";
      case CommandType.MeterRemovedFromSmc:
        return "Remover medidor do smc (Sistema)";
      case CommandType.GetVoltageOutLong:
        return "Obter Alarme Voltage Out Long";
      case CommandType.GetVoltageOutShort:
        return "Obter Alarme Voltage Out Short";
      case CommandType.ImportVoltageOutAlarm:
        return "Importar Alarmes de Voltage Out";
      case CommandType.MarkAlarmAsRead:
        return "Marcar Alame como lido";
      case CommandType.DoorStatusAbsolute:
        return "Obter Estado da Porta";
      case CommandType.MassRegistration:
        return "Cadastro em Massa";
    }
  }

  static getStatusCommand(commandStatus: StatusCommand) {
    switch (commandStatus) {
      case StatusCommand.SUCESS:
        return "Executado com Sucesso";
      case StatusCommand.WAITING:
        return "Aguardando Resposta";
      case StatusCommand.HARDWARE_FAULT:
        return "Falha de Hardware";
      case StatusCommand.TEMPORARY_FAILURE:
        return "Falha Temporaria";
      case StatusCommand.READ_WRITE_DENIED:
        return "Leitura ou Escrita Negado";
      case StatusCommand.OBJECT_UNDEFINED:
        return "Objecto Indefinido";
      case StatusCommand.OBJECT_CLASS_INCONSISTENT:
        return "Classe do Objecto Inconcistente";
      case StatusCommand.TYPE_UNMATCHED:
        return "Tipo Inconcistente";
      case StatusCommand.SCOPE_OF_ACCESS_VIOLATED:
        return "Violação do Escopo de Acesso";
      case StatusCommand.DATA_BLOCK_UNAVAILABLE:
        return "Bloco de dados não disponivel";
      case StatusCommand.LONG_GET_ABORTED:
        return "Get Long Abortado";
      case StatusCommand.LONG_SET_ABORTED:
        return "Set Long Abortado";
      case StatusCommand.NO_LONG_GET_IN_PROGRESS:
        return "Nenhum Get Long em progresso";
      case StatusCommand.NO_LONG_SET_IN_PROGRESS:
        return "Nenhum Set Long em progresso";
      case StatusCommand.DAT_BLOCK_NUMBER_INVALID:
        return "Número de bloco Invalido!";
      case StatusCommand.METERS_NOT_MATCH_WITH_RECEIVED:
        return "Lista de medidores inválida";
      case StatusCommand.TIMEOUT:
        return "Atingiu Tempo Máximo de Execução";
      case StatusCommand.OBJECT_UNAVAILABLE:
        return "Objecto não disponível";
      case StatusCommand.INCOMPLETE:
        return "Incompleto";
      case StatusCommand.INCORRECT_KEYS:
        return "Chaves Incorretas";
      case StatusCommand.INVALID_ITEM:
        return "Item Inválido";
      case StatusCommand.OTHER_REASON:
        return "Outros Motivos";
      case StatusCommand.INTERRUPTED_BY_COMMISSIONING:
        return "Interrompido por causa do comissionamento";
      case StatusCommand.OPEN_DOOR:
        return "Porta Aberta";
    }
  }

  static getStatusCommandClass(commandStatus: StatusCommand) {
    if (commandStatus === StatusCommand.WAITING) {
      return "badge badge-waiting mr-1";
    }
    return commandStatus === StatusCommand.SUCESS
      ? "badge badge-success mr-4"
      : "badge badge-danger mr-4";
  }

  static getStatusCommandBorder(commandStatus: StatusCommand) {
    return commandStatus === StatusCommand.SUCESS
      ? "1px solid #4caf50"
      : "1px solid #d22346";
  }

  static getTicketIndex(ticketId: string, ticketList: TicketDTO[]): number {
    let tickeIndex = -1;
    ticketList.forEach((ticket, index) => {
      if (ticket.ticketId === ticketId) {
        tickeIndex = index;
      }
    });
    return tickeIndex;
  }

  static getMeterCommandStatus(tickets: TicketDTO[], serial: string) {
    let status: TicketsStatus = null;
    tickets.forEach((ticket) => {
      if (
        ticket.serialMeter === serial &&
        (ticket.ticketStatus === TicketsStatus.Executing ||
          ticket.ticketStatus === TicketsStatus.Waiting)
      ) {
        status = ticket.ticketStatus;
      }
    });
    return status !== null ? this.getTicketStatus(status) : "";
  }

  static getMeterCommandStatusClass(tickets: TicketDTO[], serial: string) {
    let _ticketStatus: TicketsStatus = null;
    tickets.forEach((ticket) => {
      if (
        ticket.serialMeter === serial &&
        (ticket.ticketStatus === TicketsStatus.Executing ||
          ticket.ticketStatus === TicketsStatus.Waiting)
      ) {
        _ticketStatus = ticket.ticketStatus;
      }
    });
    return _ticketStatus !== null
      ? TicketDTO.getTicketStatusClass(_ticketStatus)
      : "";
  }

  static getCommandStatus(ticket: TicketDTO) {
    if (ticket.commandTickets.length > 0) {
      let status: StatusCommand = ticket.commandTickets[0].statusCommand;
      ticket.commandTickets.forEach((command) => {
        if (command.statusCommand !== StatusCommand.SUCESS) {
          status = command.statusCommand;
        }
      });
      return {
        command: this.getStatusCommand(status),
        status: this.getStatusCommandClass(status),
        statusCommand: status,
      };
    } else {
      return {
        command: "Sem Comando Tickets",
        status: "Sem Comando Tickets",
        statusCommand: "Sem Comando Tickets",
      };
    }
  }

  static meterHasTicket(meterSerial: string, tickets: TicketDTO[]) {
    tickets.forEach((ticket) => {
      if (ticket.serialMeter === meterSerial) {
        return true;
      }
    });
  }
}
