import { ModemDTO } from './ModemDTO';
import { CommunicationStatus, MeterDTO } from './MeterDTO';
import { KeysDTO } from './KeysDTO';
import { SmcModelDTO } from './SmcModelDTO';
import { CommandStatus } from 'src/app/core/models/CommandDTO';

export interface ISmcManufacturer {
  name: string;
  updatedAt: Date;
}

export enum AbsolutePeriod {
  One = 1,
  Fifteen = 2
}

export enum VoltageValues {
  V_220= 220,
  V_115=115,
  V_127= 127,
  V_Null= 0
}

export enum PortOpeningFlag {
    Unknown = 255,
    Opened = 1,
    Closed = 0
}

export class SmcManufacturer implements ISmcManufacturer {
  public name: string;
  public updatedAt: Date;
}

export interface ISmcModel {
  name: string;
  updatedAt: Date;
  manufacturer: SmcManufacturer;
}
export class SmcModel implements ISmcModel {
  public name: string;
  public updatedAt: Date;
  public manufacturer: SmcManufacturer;

  constructor() {
    this.manufacturer = new SmcManufacturer();
  }
}

export enum SmcModelHoneywell { }

export interface ISmcDTO {
  description: string;
  serial: string;
  latitude: number;
  longitude: number;
  smcModel: SmcModelDTO;
  modem: ModemDTO;
  keysDto: KeysDTO;
  lastDate: string;
  updatedAt: Date;
  comissioned: boolean;
  doorStatus: boolean;
  lastInterval: number;
  qtyMeters: number;
  doorAuthorizationDateTime: Date;
  onlineTimeOutInMinutes: number;
  online: boolean;
  lastSeen: Date;

  command: CommandStatus;
  smcManufacturer: SmcManufacturer;
  communicationStatus: CommunicationStatus;
  commandQueue: number;
  meters: MeterDTO[];
}

export class SmcDTO implements ISmcDTO {
  public description: string;
  public serial: string;
  public latitude: number;
  public longitude: number;
  public smcModel: SmcModelDTO;
  public modem: ModemDTO;
  public keysDto: KeysDTO;
  public lastDate: string;
  public updatedAt: Date;
  public comissioned: boolean;
  public qtyMeters: number;
  public command: CommandStatus;
  public smcManufacturer: SmcManufacturer;
  public communicationStatus: CommunicationStatus;
  public commandQueue: number;
  public doorStatus: boolean;
  public lastInterval: number;
  public doorAuthorizationDateTime: Date;
  public meters: MeterDTO[];
  public online: boolean;
  public lastSeen: Date;
  public onlineTimeOutInMinutes: number;

  constructor() {
    this.serial = null;
    this.keysDto = new KeysDTO();
    this.smcModel = new SmcModelDTO();
    this.latitude = 0;
    this.longitude = 0;
  }

  static getPortOpeningFlag(flag) {
    switch (flag) {
      case PortOpeningFlag.Opened:
        return 'Aberta';
      case PortOpeningFlag.Closed:
        return 'Fechada';
      case PortOpeningFlag.Unknown:
        return 'Desconhecido';
    }
  }

  static getPortOpeningFlagClass(flag) {
    switch (flag) {
      case PortOpeningFlag.Opened:
        return 'badge badge-success';
      default:
        return 'badge badge-danger';
    }
  }

  static getComunicationStatus(smc: SmcDTO): string {
    return smc.online ? 'Online' : 'Offline';
  }

  static getComunicationStatusClass(smc: SmcDTO): string {
    return smc.online ? 'badge badge-success mr-1' : 'badge badge-danger mr-1';
  }

  static getMetersCount(smc) {
    if (smc) {
      let count = 0;
      smc.meters.forEach((meter) => {
        count++;
      });
      return count;
    }
    return 0;
  }

  static getSmcModel(modelsList: SmcModelDTO[], modelName: string): SmcModelDTO {
    return modelsList.find(_model => _model.name.trim().toLowerCase() === modelName.trim().toLowerCase());
  }

  static getCommandQueueCount(smc: SmcDTO) {
    if (smc.meters) {
      let count = 0;
      smc.meters.forEach((meter) => {
        count += meter.commandQueue;
      });
      return count;
    }
    return 0;
  }

  static getCommandStatusClass(command: CommandStatus): string {
    switch (command) {
      case CommandStatus.WAITING_COMMAND:
        return 'badge badge-success mr-1';
      case CommandStatus.ROLLBACK:
        return 'badge badge-warning mr-1';
      case CommandStatus.CHANGING_COLLECTIVE_CUT_ALARM:
        return 'badge badge-warning mr-1';
      case CommandStatus.NIC_RESET:
        return 'badge badge-waring mr-1';
      default:
        return 'badge badge-info mr-1';
    }
  }

  static getCommandStatus(command: CommandStatus): string {
    switch (command) {
      case CommandStatus.ROLLBACK:
        return 'Executando RollBack!';
      case CommandStatus.WAITING_COMMAND:
        return 'Aguardando Comando';
      case CommandStatus.REGISTERING_KEYS:
        return 'Registrando Chaves';
      case CommandStatus.CHANGING_COLLECTIVE_CUT_ALARM:
        return 'Alterando o status de corte Coletivo';
      case CommandStatus.REQUESTING_DATE:
        return 'Requisitando Data';
      case CommandStatus.CHANGING_RELAY_STATUS:
        return 'Alterando Estado do Relé';
      case CommandStatus.REQUESTING_RELAY_STATUS:
        return 'Requisitando Estado do Relé';
      case CommandStatus.REGISTERING_DATETIME:
        return 'Registrando Data e Hora';
      case CommandStatus.REQUESTING_SERIAL:
        return 'Requisitando Serial';
      case CommandStatus.INSTANT_MEASURE:
        return 'Solicitando medida Instantânea';
      case CommandStatus.NIC_RESET:
        return 'Reset da NIC';
      case CommandStatus.SET_CURRENT:
        return 'Modificando corrente de corte';
      case CommandStatus.GET_QEE_INDICATORS:
        return 'Solicitando parametros de qualidade';
      case CommandStatus.UPLINK_PERIOD:
        return 'Alterando Periodo de Uplink de leitura';
      case CommandStatus.RECOVER_FROZEN_DATA:
        return 'Recuperando Energia';
      case CommandStatus.RECOVER_MASS_MEMORY:
        return 'Recuperando Memória de Massa';
      case CommandStatus.RECOVER_EVENT_QEE_INDICATOR:
        return 'Recuperando parametros de qualidade';
      case CommandStatus.GBT:
        return 'GBT';
      case CommandStatus.REQUESTING_RELAY_STATUS_ALL_SMC:
        return 'Requisitando status dos relés';
      case CommandStatus.REQUEST_DOOR_STATUS:
        return 'Obtendo Status do corte coletivo';
      case CommandStatus.RELAY_ON_ALL_METERS_SMC:
        return 'Religando todos os clientes';
    }
  }

  static findSMCIndex(smcSerial: string, smcList: SmcDTO[]): any {
    smcList.forEach((smc, index) => {
      if (smc.serial === smcSerial) {
        return index;
      }
    });
  }

  static getSpecificSmc(smcSerial: string, smcsList: SmcDTO[]): SmcDTO {
    return smcsList.find((_smc) => _smc.serial === smcSerial) || null;
  }

  public static getCommunicationStatusClass(
    communicationStatus: CommunicationStatus
  ): string {
    switch (communicationStatus) {
      case CommunicationStatus.NO_INFORMATION:
        return 'badge badge-info mr-1';
      case CommunicationStatus.BUSY:
        return 'badge badge-warning mr-1';
      case CommunicationStatus.COMMUNICATION_FAILED:
        return 'badge badge-danger mr-1';
      case CommunicationStatus.SUCCESS:
        return 'badge badge-success mr-1';
    }
  }
}
