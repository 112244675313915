import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IMeterDTO, MeterDTO } from "src/app/core/models/MeterDTO";

import * as XLS from "xlsx";
import { FileService } from "../../services/file.service";
import { HandleErrorService } from "../../services/handle-error.service";
import { MetersService } from "../../services/meters.service";
import { METERCONECTIONPHASE } from "../meter-register-table/meter-register-table.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-meters-file-upload-table",
  templateUrl: "./meters-file-upload-table.component.html",
  styleUrls: ["./meters-file-upload-table.component.scss"],
})
export class MetersFileUploadTableComponent {
  @Input() meters: IMeterDTO[] = [];
  @Input() isUploadingFile: boolean = false;

  @Output() descartAllMeters: EventEmitter<void> = new EventEmitter();
  @Output() upload: EventEmitter<void> = new EventEmitter();
  @Output() edit: EventEmitter<void> = new EventEmitter();
  @Output() descartLine: EventEmitter<string> = new EventEmitter();
  isLoadingAllMeters = false;

  MeterDTO = MeterDTO;

  public serial: string = null;
  public isEdit: boolean = false;

  public fileUrlDownload =
    "./assets/models/modelo de cadastro dos medidores.xlsx";

  constructor(
    private _modalService: NgbModal,
    private meterServices: MetersService,
    private handleService: HandleErrorService,
    public fileService: FileService,
  ) {}

  openModal(content, serial?: string) {
    if (serial) this.serial = serial;
    this._modalService.open(content, { centered: true, backdrop: "static" });
  }

  closeModal() {
    this._modalService.dismissAll();
  }

  removeLine() {
    this.descartLine.emit(this.serial);
  }

  descartMeters() {
    this.descartAllMeters.emit();
  }

  uploadMeters() {
    this.upload.emit();
  }

  editMeters() {
    this.edit.emit();
  }

  downloadAllMeters() {
    this.isLoadingAllMeters = true;
    this.meterServices
      .getAllMeters()
      .toPromise()
      .then((response: MeterDTO[]) => {
        const rows = this.processMeters(response);
        const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
        workSheet["!cols"] = [{ width: 45 }];

        const workbook: XLS.WorkBook = {
          Sheets: {
            Medidores: workSheet,
          },
          SheetNames: ["Medidores"],
        };
        const excelBuffer: any = XLS.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        this.fileService.saveFileAsExcel(excelBuffer, "Todos os Medidores");

        this.isLoadingAllMeters = false;
      })
      .catch((error) => {
        this.handleService.handle(error);
        this.isLoadingAllMeters = false;
      });
  }

  private processMeters(meters: MeterDTO[]) {
    let metersList = [];

    meters.forEach((_meter) => {
      let tags: string = "";
      let registers: string = "";
      _meter.tags.forEach((tag) => {
        tags += tag.visibleId + ";";
      });
      if (tags.slice(tags.length - 1) === ";") {
        tags = tags.slice(0, tags.length - 1);
      }
      _meter.meterRegisters.forEach((_register) => {
        registers += _register.code + ";";
      });
      if (registers.slice(registers.length - 1) === ";")
        registers = registers.slice(0, registers.length - 1);

      const meter = {
        phase: MeterDTO.getPhaseValue(_meter),
        meterModel: _meter.meterModel.name,
        rateType: _meter.rateType.name,
        smc: _meter.smc !== null ? _meter.smc.serial : null,
        serial: _meter.serial,
        meterResgisters: registers,
        latitude: _meter.latitude,
        longitude: _meter.longitude,
        installation: _meter.installation,
        tags: tags,
        connectionPhase: this.getConnectionPhase(_meter.connectionPhase),
        hasTransparent: _meter.hasTransparent,
      };
      metersList.push(meter);
    });
    return metersList;
  }

  private getConnectionPhase(phase: number) {
    return METERCONECTIONPHASE[phase];
  }
}
